import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

const HeaderWrapper = styled.header`
  background-color: #ffffff;
  // background-color: #fffdfa;
  width: 100%;
  padding: 20px 0;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Logo = styled(Link)`
  font-size: 24px;
  font-weight: bold;
  color: #111827;
  text-decoration: none;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const NavLinks = styled.div`
  position: relative;
`;

const DropdownButton = styled.button`
  padding: 8px 16px;
  background-color: #1f2937; /* Change this to your preferred color */
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #374151; /* Slightly lighter shade for hover */
  }

  @media (max-width: 768px) {
    padding: 8px 12px;
    font-size: 14px;
    width: 100%;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 1000;

  a,
  button {
    display: block;
    padding: 10px 15px;
    color: #111827;
    text-decoration: none;
    background: none;
    border: none;
    text-align: left;
    width: 100%;
    cursor: pointer;

    &:hover {
      background-color: #f3f4f6;
    }
  }
`;

const HeaderComponent = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    await auth.signOut();
    navigate('/');
  };

  const handleDropdownToggle = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleLinkClick = () => {
    setDropdownVisible(false);
  };

  const handleOutsideClick = (event) => {
    if (!event.target.closest('#dropdown-button') && !event.target.closest('#dropdown-menu')) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [dropdownVisible]);

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <Logo to="/" style={{ color: '#111827', fontSize: '1.5rem', fontWeight: 'bold' }}>
          Luminate
        </Logo>
        <NavLinks>
          {isLoggedIn ? (
            <>
              <DropdownButton id="dropdown-button" onClick={handleDropdownToggle}>
                Account
              </DropdownButton>
              {dropdownVisible && (
                <DropdownMenu id="dropdown-menu">
                  <Link to="/account-settings" onClick={handleLinkClick}>Account Settings</Link>
                  <button onClick={handleLogout}>Logout</button>
                </DropdownMenu>
              )}
            </>
          ) : (
            <DropdownButton as={Link} to="/login" style={{ textDecoration: 'none' }}>Login</DropdownButton>
          )}
        </NavLinks>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default HeaderComponent;


// import React, { useState, useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import styled from 'styled-components';
// import { auth } from '../firebase';
// import { onAuthStateChanged } from 'firebase/auth';

// const HeaderWrapper = styled.div`
//   background-color: #ffffff;
//   width: 100%;
//   padding: 20px 0;
//   position: sticky;
//   top: 0;
//   z-index: 1000;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
// `;

// const HeaderContainer = styled.div`
//   width: 100%;
//   max-width: 1200px;
//   margin: 0 auto;
//   padding: 0 20px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;

//   @media (max-width: 768px) {
//     flex-direction: column;
//     align-items: center;
//   }
// `;

// const Logo = styled(Link)`
//   font-size: 24px;
//   font-weight: bold;
//   color: #111827;
//   text-decoration: none;

//   @media (max-width: 768px) {
//     margin-bottom: 10px;
//   }
// `;

// const NavLinks = styled.div`
//   position: relative;
// `;

// const DropdownButton = styled.button`
//   padding: 10px 15px;
//   background-color: #111827;
//   color: #ffffff;
//   border: none;
//   border-radius: 9999px;
//   cursor: pointer;
//   font-size: 16px;
//   font-weight: bold;
//   text-decoration: none;

//   &:hover {
//     background-color: #1f2937;
//   }

//   @media (max-width: 768px) {
//     padding: 8px 12px;
//     font-size: 14px;
//     width: 100%;
//   }
// `;

// const DropdownMenu = styled.div`
//   position: absolute;
//   right: 0;
//   top: 100%;
//   background-color: #ffffff;
//   border-radius: 5px;
//   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
//   overflow: hidden;
//   z-index: 1000;

//   a,
//   button {
//     display: block;
//     padding: 10px 15px;
//     color: #111827;
//     text-decoration: none;
//     background: none;
//     border: none;
//     text-align: left;
//     width: 100%;
//     cursor: pointer;

//     &:hover {
//       background-color: #f3f4f6;
//     }
//   }
// `;

// const HeaderComponent = () => {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [dropdownVisible, setDropdownVisible] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (user) => {
//       if (user) {
//         setIsLoggedIn(true);
//       } else {
//         setIsLoggedIn(false);
//       }
//     });

//     return () => unsubscribe();
//   }, []);

//   const handleLogout = async () => {
//     await auth.signOut();
//     navigate('/');
//   };

//   const handleDropdownToggle = () => {
//     setDropdownVisible(!dropdownVisible);
//   };

//   const handleLinkClick = () => {
//     setDropdownVisible(false);
//   };

//   const handleOutsideClick = (event) => {
//     if (!event.target.closest('#dropdown-button') && !event.target.closest('#dropdown-menu')) {
//       setDropdownVisible(false);
//     }
//   };

//   useEffect(() => {
//     if (dropdownVisible) {
//       document.addEventListener('mousedown', handleOutsideClick);
//     } else {
//       document.removeEventListener('mousedown', handleOutsideClick);
//     }

//     return () => {
//       document.removeEventListener('mousedown', handleOutsideClick);
//     };
//   }, [dropdownVisible]);

//   return (
//     <HeaderWrapper>
//       <HeaderContainer>
//         <Logo to="/">Luminate</Logo>
//         <NavLinks>
//           {isLoggedIn ? (
//             <>
//               <DropdownButton id="dropdown-button" onClick={handleDropdownToggle}>
//                 Account
//               </DropdownButton>
//               {dropdownVisible && (
//                 <DropdownMenu id="dropdown-menu">
//                   <Link to="/account-settings" onClick={handleLinkClick}>Account Settings</Link>
//                   <button onClick={handleLogout}>Logout</button>
//                 </DropdownMenu>
//               )}
//             </>
//           ) : (
//             <DropdownButton as={Link} to="/login" style={{ textDecoration: 'none' }}>Login</DropdownButton>
//           )}
//         </NavLinks>
//       </HeaderContainer>
//     </HeaderWrapper>
//   );
// };

// export default HeaderComponent;
