import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ThumbsUp, ThumbsDown } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import user1 from '../assets/User1.png';
import user2 from '../assets/User2.png';
import user3 from '../assets/User3.png';
import user4 from '../assets/User4.png';

const CarouselContainer = styled(motion.div)`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  perspective: 1000px;
  overflow: hidden;
  padding: 0rem 1rem;
`;

const CommentCard = styled(motion.div)`
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 700px;
  position: absolute;
  cursor: grab;
  text-align: left;

  @media (max-width: 768px) { /* Tablet */
    width: 500px;
    padding: 20px;
  }

  @media (max-width: 480px) { /* Mobile */
    width: 90%; /* Adapt to screen size */
    padding: 15px;
  }
`;

const CommentHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Avatar = styled.div`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: #ddd;
  margin-right: 20px;
  overflow: hidden;
  border: 2px solid #fff; /* Add a contour (border) */

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 480px) { /* Mobile */
    width: 60px; /* Smaller avatar for mobile */
    height: 60px;
  }
`;

const Username = styled.span`
  font-weight: bold;
  font-size: 1.2rem;
  color: #333;
  margin-right: 10px;

  @media (max-width: 480px) { /* Mobile */
    font-size: 1rem; /* Smaller font for mobile */
  }
`;

const CommentTime = styled.span`
  font-size: 1rem;
  color: #999;

  @media (max-width: 480px) { /* Mobile */
    font-size: 0.9rem; /* Smaller font for mobile */
  }
`;

const CommentBody = styled.p`
  font-size: 1.2rem;
  color: #111;
  line-height: 1.7;
  margin: 15px 0;

  @media (max-width: 480px) { /* Mobile */
    font-size: 1rem; /* Smaller font for mobile */
  }
`;

const CommentActions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  color: #666;
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;

  &:hover {
    color: #000;
  }

  svg {
    margin-right: 8px;
  }

  @media (max-width: 480px) { /* Mobile */
    font-size: 0.9rem; /* Smaller font for mobile */
    margin-right: 15px; /* Less margin on mobile */
  }
`;

const RepliesLink = styled.div`
  font-size: 1rem;
  color: #065fd4;
  cursor: pointer;
  margin-top: 15px;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 480px) { /* Mobile */
    font-size: 0.9rem; /* Smaller font for mobile */
  }
`;

const commentsData = [
  {
    id: 1,
    username: "Sammy1998",
    avatar: user1,
    text: "Great video! One suggestion: try adding timestamps in the description for different topics. It makes it easier for viewers to navigate through longer videos.",
    likes: "19 likes",
    replies: "23",
    time: "3 hours ago",
  },
  {
    id: 2,
    username: "JeromeJJ",
    avatar: user2,
    text: "Your lighting has improved so much! Maybe consider investing in a better microphone next? Clear audio can really elevate the overall quality of your videos.",
    likes: "18",
    replies: "3",
    time: "53 mins ago",
  },
  {
    id: 3,
    username: "MariaGiovanna",
    avatar: user3,
    text: "The pacing of this video was perfect. Have you thought about adding some subtle background music? It can help maintain viewer engagement during slower sections.",
    likes: "5.4K",
    replies: "193",
    time: "17 hours ago",
  },
  {
    id: 4,
    username: "Chad_223",
    avatar: user4,
    text: "Awesome video! Would love to see more fishing content like this in the future",
    likes: "1",
    replies: "0",
    time: "2 minutes ago",
  }
];

const YouTubeCommentsCarousel = () => {
  const [comments, setComments] = useState(commentsData);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % comments.length);
    }, 4000);

    return () => clearInterval(timer);
  }, [comments.length]);

  const getVisibleComments = () => {
    const visibleComments = [];
    for (let i = 0; i < 3; i++) {
      const index = (currentIndex + i) % comments.length;
      visibleComments.push({ ...comments[index], key: `${comments[index].id}-${i}` });
    }
    return visibleComments;
  };

  return (
    <AnimatePresence>
      <CarouselContainer
        initial={{ opacity: 0, translateY: 20 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{ duration: 0.8, delay: 1 }} // Delay added here
      >
        <AnimatePresence initial={false} mode="popLayout">
          {getVisibleComments().map((comment, index) => {
            const isTopCard = index === 0;

            return (
              <CommentCard
                key={comment.key}
                initial={{
                  scale: isTopCard ? 1 : 0.9 - index * 0.05,
                  y: index * 20,
                  rotateZ: isTopCard ? 0 : index === 1 ? -5 : 5,
                  zIndex: 3 - index,
                  opacity: isTopCard ? 1 : 0.7,
                }}
                animate={{
                  scale: isTopCard ? 1 : 0.9 - index * 0.05,
                  y: index * 20,
                  rotateZ: isTopCard ? 0 : index === 1 ? -5 : 5,
                  zIndex: 3 - index,
                  opacity: isTopCard ? 1 : 0.7,
                  transition: { duration: 0.5 },
                }}
                exit={
                  isTopCard
                    ? {
                        y: -100,
                        opacity: 0,
                        transition: { duration: 0.5 },
                      }
                    : {
                        transition: { duration: 0.5 },
                      }
                }
              >
                <CommentHeader>
                  <Avatar>
                    <img src={comment.avatar || '/api/placeholder/60/60'} alt={comment.username} />
                  </Avatar>
                  <div>
                    <Username>{comment.username}</Username>
                    <CommentTime>{comment.time}</CommentTime>
                  </div>
                </CommentHeader>
                <CommentBody>{comment.text}</CommentBody>
                <CommentActions>
                  <ActionButton>
                    <ThumbsUp size={20} /> {comment.likes}
                  </ActionButton>
                  <ActionButton>
                    <ThumbsDown size={20} /> Reply
                  </ActionButton>
                </CommentActions>
                <RepliesLink>{comment.replies} replies</RepliesLink>
              </CommentCard>
            );
          })}
        </AnimatePresence>
      </CarouselContainer>
    </AnimatePresence>
  );
};

export default YouTubeCommentsCarousel;









// import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';
// import { ThumbsUp, ThumbsDown } from 'lucide-react';
// import { motion, AnimatePresence } from 'framer-motion';
// import user1 from '../assets/User1.png';
// import user2 from '../assets/User2.png';
// import user3 from '../assets/User3.png';
// import user4 from '../assets/User4.png';

// const CarouselContainer = styled(motion.div)`
//   width: 100%;
//   height: 400px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: relative;
//   perspective: 1000px;
//   overflow: hidden;
//   padding: 0rem 1rem;
// `;

// const CommentCard = styled(motion.div)`
//   background-color: #fff;
//   border: 1px solid #e0e0e0;
//   border-radius: 20px;
//   padding: 30px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   width: 700px;
//   position: absolute;
//   cursor: grab;
//   text-align: left;
// `;

// const CommentHeader = styled.div`
//   display: flex;
//   align-items: center;
//   margin-bottom: 20px;
// `;

// const Avatar = styled.div`
//   width: 75px;
//   height: 75px;
//   border-radius: 50%;
//   background-color: #ddd;
//   margin-right: 20px;
//   overflow: hidden;
//   border: 2px solid #fff; /* Add a contour (border) */

//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }
// `;

// const Username = styled.span`
//   font-weight: bold;
//   font-size: 1.2rem;
//   color: #333;
//   margin-right: 10px;
// `;

// const CommentTime = styled.span`
//   font-size: 1rem;
//   color: #999;
// `;

// const CommentBody = styled.p`
//   font-size: 1.2rem;
//   color: #111;
//   line-height: 1.7;
//   margin: 15px 0;
// `;

// const CommentActions = styled.div`
//   display: flex;
//   align-items: center;
//   margin-top: 15px;
// `;

// const ActionButton = styled.button`
//   background: none;
//   border: none;
//   color: #666;
//   font-size: 1rem;
//   display: flex;
//   align-items: center;
//   margin-right: 30px;
//   cursor: pointer;

//   &:hover {
//     color: #000;
//   }

//   svg {
//     margin-right: 8px;
//   }
// `;

// const RepliesLink = styled.div`
//   font-size: 1rem;
//   color: #065fd4;
//   cursor: pointer;
//   margin-top: 15px;

//   &:hover {
//     text-decoration: underline;
//   }
// `;

// const commentsData = [
//   {
//     id: 1,
//     username: "Sammy1998",
//     avatar: user1,
//     text: "Great video! One suggestion: try adding timestamps in the description for different topics. It makes it easier for viewers to navigate through longer videos.",
//     likes: "19 likes",
//     replies: "23",
//     time: "3 hours ago",
//   },
//   {
//     id: 2,
//     username: "JeromeJJ",
//     avatar: user2,
//     text: "Your lighting has improved so much! Maybe consider investing in a better microphone next? Clear audio can really elevate the overall quality of your videos.",
//     likes: "18",
//     replies: "3",
//     time: "53 mins ago",
//   },
//   {
//     id: 3,
//     username: "MariaGiovanna",
//     avatar: user3,
//     text: "The pacing of this video was perfect. Have you thought about adding some subtle background music? It can help maintain viewer engagement during slower sections.",
//     likes: "5.4K",
//     replies: "193",
//     time: "17 hours ago",
//   },
//   {
//     id: 4,
//     username: "MrBigBro",
//     avatar: user4,
//     text: "Awesome video! Would love to see more fishing content like this in the future",
//     likes: "1",
//     replies: "0",
//     time: "2 minutes ago",
//   }
// ];

// const YouTubeCommentsCarousel = () => {
//   const [comments, setComments] = useState(commentsData);
//   const [currentIndex, setCurrentIndex] = useState(0);

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setCurrentIndex((prevIndex) => (prevIndex + 1) % comments.length);
//     }, 4000);

//     return () => clearInterval(timer);
//   }, [comments.length]);

//   const getVisibleComments = () => {
//     const visibleComments = [];
//     for (let i = 0; i < 3; i++) {
//       const index = (currentIndex + i) % comments.length;
//       visibleComments.push({ ...comments[index], key: `${comments[index].id}-${i}` });
//     }
//     return visibleComments;
//   };

//   return (
//     <AnimatePresence>
//       <CarouselContainer
//         initial={{ opacity: 0, translateY: 20 }}
//         animate={{ opacity: 1, translateY: 0 }}
//         transition={{ duration: 0.8, delay: 1 }} // Delay added here
//       >
//         <AnimatePresence initial={false} mode="popLayout">
//           {getVisibleComments().map((comment, index) => {
//             const isTopCard = index === 0;

//             return (
//               <CommentCard
//                 key={comment.key}
//                 initial={{
//                   scale: isTopCard ? 1 : 0.9 - index * 0.05,
//                   y: index * 20,
//                   rotateZ: isTopCard ? 0 : index === 1 ? -5 : 5,
//                   zIndex: 3 - index,
//                   opacity: isTopCard ? 1 : 0.7,
//                 }}
//                 animate={{
//                   scale: isTopCard ? 1 : 0.9 - index * 0.05,
//                   y: index * 20,
//                   rotateZ: isTopCard ? 0 : index === 1 ? -5 : 5,
//                   zIndex: 3 - index,
//                   opacity: isTopCard ? 1 : 0.7,
//                   transition: { duration: 0.5 },
//                 }}
//                 exit={
//                   isTopCard
//                     ? {
//                         y: -100,
//                         opacity: 0,
//                         transition: { duration: 0.5 },
//                       }
//                     : {
//                         transition: { duration: 0.5 },
//                       }
//                 }
//               >
//                 <CommentHeader>
//                   <Avatar>
//                     <img src={comment.avatar || '/api/placeholder/60/60'} alt={comment.username} />
//                   </Avatar>
//                   <div>
//                     <Username>{comment.username}</Username>
//                     <CommentTime>{comment.time}</CommentTime>
//                   </div>
//                 </CommentHeader>
//                 <CommentBody>{comment.text}</CommentBody>
//                 <CommentActions>
//                   <ActionButton>
//                     <ThumbsUp size={20} /> {comment.likes}
//                   </ActionButton>
//                   <ActionButton>
//                     <ThumbsDown size={20} /> Reply
//                   </ActionButton>
//                 </CommentActions>
//                 <RepliesLink>{comment.replies} replies</RepliesLink>
//               </CommentCard>
//             );
//           })}
//         </AnimatePresence>
//       </CarouselContainer>
//     </AnimatePresence>
//   );
// };

// export default YouTubeCommentsCarousel;


























































// import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';
// import { ThumbsUp, ThumbsDown } from 'lucide-react';
// import { motion, AnimatePresence } from 'framer-motion';
// import user1 from '../assets/User1.png';
// import user2 from '../assets/User2.png';
// import user3 from '../assets/User3.png';
// import user4 from '../assets/User4.png';


// const CarouselContainer = styled.div`
//   width: 100%;
//   height: 400px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: relative;
//   perspective: 1000px;
//   overflow: hidden;
//   padding: 0rem 1rem;
// `;

// const CommentCard = styled(motion.div)`
//   background-color: #fff;
//   border: 1px solid #e0e0e0;
//   border-radius: 20px;
//   padding: 30px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   width: 700px;
//   position: absolute;
//   cursor: grab;
//   text-align: left;
// `;

// const CommentHeader = styled.div`
//   display: flex;
//   align-items: center;
//   margin-bottom: 20px;
// `;

// const Avatar = styled.div`
//   width: 60px;
//   height: 60px;
//   border-radius: 50%;
//   background-color: #ddd;
//   margin-right: 20px;
//   overflow: hidden;
//   border: 2px solid #333; /* Add a contour (border) */

//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }
// `;

// const Username = styled.span`
//   font-weight: bold;
//   font-size: 1.2rem;
//   color: #333;
//   margin-right: 10px;
// `;

// const CommentTime = styled.span`
//   font-size: 1rem;
//   color: #999;
// `;

// const CommentBody = styled.p`
//   font-size: 1.2rem;
//   // color: #555;
//   color: #111;
//   line-height: 1.7;
//   margin: 15px 0;
// `;

// const CommentActions = styled.div`
//   display: flex;
//   align-items: center;
//   margin-top: 15px;
// `;

// const ActionButton = styled.button`
//   background: none;
//   border: none;
//   color: #666;
//   font-size: 1rem;
//   display: flex;
//   align-items: center;
//   margin-right: 30px;
//   cursor: pointer;

//   &:hover {
//     color: #000;
//   }

//   svg {
//     margin-right: 8px;
//   }
// `;

// const RepliesLink = styled.div`
//   font-size: 1rem;
//   color: #065fd4;
//   cursor: pointer;
//   margin-top: 15px;

//   &:hover {
//     text-decoration: underline;
//   }
// `;

// const commentsData = [
//   {
//     id: 1,
//     username: "TechTipsGuru",
//     avatar: user1,
//     text: "Great video! One suggestion: try adding timestamps in the description for different topics. It makes it easier for viewers to navigate through longer videos.",
//     likes: "3.2K",
//     replies: "87",
//     time: "2 days ago",
//   },
//   {
//     id: 2,
//     username: "ContentCreator101",
//     avatar: user2,
//     text: "Your lighting has improved so much! Maybe consider investing in a better microphone next? Clear audio can really elevate the overall quality of your videos.",
//     likes: "1.8K",
//     replies: "52",
//     time: "1 week ago",
//   },
//   {
//     id: 3,
//     username: "EditingPro",
//     avatar: user3,
//     text: "The pacing of this video was perfect. Have you thought about adding some subtle background music? It can help maintain viewer engagement during slower sections.",
//     likes: "5.4K",
//     replies: "193",
//     time: "3 days ago",
//   },
//   {
//     id: 4,
//     username: "SEOExpert",
//     avatar: user4,
//     text: "Great content as always! Quick tip: try to include your main keyword in the first 30 seconds of your video. It can help with search rankings and discoverability.",
//     likes: "2.7K",
//     replies: "104",
//     time: "5 days ago",
//   }
// ];

// const YouTubeCommentsCarousel = () => {
//   const [comments, setComments] = useState(commentsData);
//   const [currentIndex, setCurrentIndex] = useState(0);

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setCurrentIndex((prevIndex) => (prevIndex + 1) % comments.length);
//     }, 4000);

//     return () => clearInterval(timer);
//   }, [comments.length]);

//   const getVisibleComments = () => {
//     const visibleComments = [];
//     for (let i = 0; i < 3; i++) {
//       const index = (currentIndex + i) % comments.length;
//       visibleComments.push({ ...comments[index], key: `${comments[index].id}-${i}` });
//     }
//     return visibleComments;
//   };

//   return (
//     <CarouselContainer>
//       <AnimatePresence initial={false} mode="popLayout">
//         {getVisibleComments().map((comment, index) => {
//           const isTopCard = index === 0;

//           return (
//             <CommentCard
//               key={comment.key}
//               initial={{
//                 scale: isTopCard ? 1 : 0.9 - index * 0.05,
//                 y: index * 20,
//                 rotateZ: isTopCard ? 0 : index === 1 ? -5 : 5,
//                 zIndex: 3 - index,
//                 opacity: isTopCard ? 1 : 0.7,
//               }}
//               animate={{
//                 scale: isTopCard ? 1 : 0.9 - index * 0.05,
//                 y: index * 20,
//                 rotateZ: isTopCard ? 0 : index === 1 ? -5 : 5,
//                 zIndex: 3 - index,
//                 opacity: isTopCard ? 1 : 0.7,
//                 transition: { duration: 0.5 },
//               }}
//               exit={isTopCard ? {
//                 y: -100,
//                 opacity: 0,
//                 transition: { duration: 0.5 },
//               } : {
//                 transition: { duration: 0.5 },
//               }}
//             >
//               <CommentHeader>
//                 <Avatar>
//                   <img src={comment.avatar || '/api/placeholder/60/60'} alt={comment.username} />
//                 </Avatar>
//                 <div>
//                   <Username>{comment.username}</Username>
//                   <CommentTime>{comment.time}</CommentTime>
//                 </div>
//               </CommentHeader>
//               <CommentBody>{comment.text}</CommentBody>
//               <CommentActions>
//                 <ActionButton>
//                   <ThumbsUp size={20} /> {comment.likes}
//                 </ActionButton>
//                 <ActionButton>
//                   <ThumbsDown size={20} /> Reply
//                 </ActionButton>
//               </CommentActions>
//               <RepliesLink>{comment.replies} replies</RepliesLink>
//             </CommentCard>
//           );
//         })}
//       </AnimatePresence>
//     </CarouselContainer>
//   );
// };

// export default YouTubeCommentsCarousel;








// import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';
// import { ThumbsUp, ThumbsDown } from 'lucide-react';
// import { motion, AnimatePresence } from 'framer-motion';

// const CarouselContainer = styled.div`
//   width: 100%;
//   height: 600px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: relative;
//   perspective: 1000px;
// `;

// const CommentCard = styled(motion.div)`
//   background-color: #fff;
//   border: 1px solid #e0e0e0;
//   border-radius: 8px;
//   padding: 30px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   width: 600px;
//   position: absolute;
//   cursor: grab;
//   text-align: left;
// `;

// const CommentHeader = styled.div`
//   display: flex;
//   align-items: center;
//   margin-bottom: 20px;
// `;

// const Avatar = styled.div`
//   width: 60px;
//   height: 60px;
//   border-radius: 50%;
//   background-color: #ddd;
//   margin-right: 20px;
//   overflow: hidden;
//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }
// `;

// const Username = styled.span`
//   font-weight: bold;
//   font-size: 1.2rem;
//   color: #333;
//   margin-right: 10px;
// `;

// const CommentTime = styled.span`
//   font-size: 1rem;
//   color: #999;
// `;

// const CommentBody = styled.p`
//   font-size: 1.1rem;
//   color: #555;
//   line-height: 1.7;
//   margin: 15px 0;
// `;

// const CommentActions = styled.div`
//   display: flex;
//   align-items: center;
//   margin-top: 15px;
// `;

// const ActionButton = styled.button`
//   background: none;
//   border: none;
//   color: #666;
//   font-size: 1rem;
//   display: flex;
//   align-items: center;
//   margin-right: 30px;
//   cursor: pointer;

//   &:hover {
//     color: #000;
//   }

//   svg {
//     margin-right: 8px;
//   }
// `;

// const RepliesLink = styled.div`
//   font-size: 1rem;
//   color: #065fd4;
//   cursor: pointer;
//   margin-top: 15px;

//   &:hover {
//     text-decoration: underline;
//   }
// `;

// const commentsData = [
//   {
//     id: 1,
//     username: "christiaanpeeters9716",
//     avatar: "",
//     text: "Finally Brodie met some people with more energy than himself 😂",
//     likes: "18K",
//     replies: "90",
//     time: "1 year ago",
//   },
//   {
//     id: 2,
//     username: "annabellehivojohn462",
//     avatar: "",
//     text: "I am from Vanuatu and people here are very friendly. I'm glad you get to share some of our amazing traditions here.",
//     likes: "3.5K",
//     replies: "89",
//     time: "1 year ago",
//   },
//   {
//     id: 3,
//     username: "ooooooo8853",
//     avatar: "",
//     text: "Imagine living in paradise where you don't have to worry about bills, taxes, and work.",
//     likes: "20K",
//     replies: "619",
//     time: "1 year ago",
//   }
// ];

// const YouTubeCommentsCarousel = () => {
//   const [comments, setComments] = useState(commentsData);

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setComments((prevComments) => {
//         const [first, ...rest] = prevComments; // Take the first card and move it to the back
//         return [...rest, first]; // Reorder the array
//       });
//     }, 4000); // Increased interval for auto swipe

//     return () => clearInterval(timer);
//   }, []);

//   return (
//     <CarouselContainer>
//       <AnimatePresence>
//         {comments.map((comment, index) => {
//           const zIndex = comments.length - index; // Dynamically set z-index based on position

//           return (
//             <CommentCard
//               key={comment.id}
//               style={{ zIndex }} // Set z-index dynamically
//               initial={{
//                 scale: 1 - index * 0.05, // Scale down a bit for cards at the back
//                 y: 20 * index, // Stack effect
//                 rotateZ: index % 2 === 0 ? -5 * index : 5 * index, // Dynamic incline effect
//                 opacity: 1, // Full opacity for all cards
//               }}
//               animate={{
//                 scale: 1 - index * 0.05, // Continue scaling as the card moves
//                 y: 20 * index,
//                 rotateZ: index % 2 === 0 ? -5 * index : 5 * index,
//                 opacity: 1, // Full opacity for all cards
//                 transition: { duration: 1, ease: "easeInOut" }, // Added smoother transition
//               }}
//               exit={{
//                 scale: 0.9, // Slight scale effect as it moves out
//                 y: 20 * (comments.length - index), // Move to the bottom of the stack
//                 rotateZ: index % 2 === 0 ? -10 : 10, // Rotate slightly more for effect
//                 opacity: 1, // Keep full opacity
//                 transition: { duration: 1, ease: "easeInOut" }, // Added smoother transition
//               }}
//             >
//               <CommentHeader>
//                 <Avatar>
//                   <img src={comment.avatar || '/api/placeholder/60/60'} alt={comment.username} />
//                 </Avatar>
//                 <div>
//                   <Username>{comment.username}</Username>
//                   <CommentTime>{comment.time}</CommentTime>
//                 </div>
//               </CommentHeader>
//               <CommentBody>{comment.text}</CommentBody>
//               <CommentActions>
//                 <ActionButton>
//                   <ThumbsUp size={20} /> {comment.likes}
//                 </ActionButton>
//                 <ActionButton>
//                   <ThumbsDown size={20} /> Reply
//                 </ActionButton>
//               </CommentActions>
//               <RepliesLink>{comment.replies} replies</RepliesLink>
//             </CommentCard>
//           );
//         })}
//       </AnimatePresence>
//     </CarouselContainer>
//   );
// };

// export default YouTubeCommentsCarousel;







// import React, { useState } from 'react';
// import Slider from 'react-slick';
// import styled from 'styled-components';
// import { ThumbsUp, ThumbsDown } from 'lucide-react'; // Make sure Lucide Icons is installed

// // Styled Components
// const CarouselContainer = styled.div`
//   width: 100%;
//   padding: 40px 0;
//   margin-top: 100px;
//   margin-bottom: 100px;
// `;

// const CommentCard = styled.div`
//   background-color: #fff;
//   border: 1px solid #e0e0e0;
//   border-radius: 8px;
//   padding: 20px;
//   margin: 0 10px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   max-width: 400px;
//   text-align: left; /* Ensures all text is left-aligned */
//   transition: transform 0.3s ease; /* Smooth transition for the tilt effect */
// `;

// const CommentHeader = styled.div`
//   display: flex;
//   align-items: center;
//   margin-bottom: 15px;
// `;

// const Avatar = styled.div`
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   background-color: #ddd;
//   margin-right: 15px;
//   overflow: hidden;
//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }
// `;

// const Username = styled.span`
//   font-weight: bold;
//   font-size: 0.9rem;
//   color: #333;
//   margin-right: 10px;
// `;

// const CommentTime = styled.span`
//   font-size: 0.8rem;
//   color: #999;
// `;

// const CommentBody = styled.p`
//   font-size: 0.9rem;
//   color: #555;
//   line-height: 1.5;
//   margin: 10px 0;
// `;

// const CommentActions = styled.div`
//   display: flex;
//   align-items: center;
//   margin-top: 10px;
// `;

// const ActionButton = styled.button`
//   background: none;
//   border: none;
//   color: #666;
//   font-size: 0.9rem;
//   display: flex;
//   align-items: center;
//   margin-right: 20px;
//   cursor: pointer;

//   &:hover {
//     color: #000;
//   }

//   svg {
//     margin-right: 5px;
//   }
// `;

// const RepliesLink = styled.div`
//   font-size: 0.8rem;
//   color: #065fd4; /* YouTube-style blue color for links */
//   cursor: pointer;
//   margin-top: 10px;

//   &:hover {
//     text-decoration: underline;
//   }
// `;

// const getRandomTilt = () => {
//   // Generate a random tilt between -5 and 5 degrees
//   return Math.random() * 10 - 5;
// };

// const YouTubeCommentsCarousel = () => {
//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   // Sample comments data
//   const comments = [
//     {
//       id: 1,
//       username: "ooooooo8853",
//       avatar: "", // Could be a URL to an avatar image
//       text: "Imagine living in paradise where you don’t have to worry about bills, taxes, and work.",
//       likes: "20K",
//       replies: "619",
//       time: "1 year ago",
//     },
//     {
//       id: 2,
//       username: "annabellehivojohn462",
//       avatar: "",
//       text: "I am from Vanuatu and people here are very friendly. I'm glad you get to share some of our amazing traditions here..",
//       likes: "3.5K",
//       replies: "89",
//       time: "1 year ago",
//     },
//     {
//       id: 3,
//       username: "christiaanpeeters9716",
//       avatar: "",
//       text: "Finally Brodie met some people with more energy than himself 😂",
//       likes: "18K",
//       replies: "90",
//       time: "1 year ago",
//     },
//   ];

//   // Generate random tilt angles for each comment
//   const [tilts] = useState(comments.map(() => getRandomTilt()));

//   return (
//     <CarouselContainer>
//       <Slider {...settings}>
//         {comments.map((comment, index) => (
//           <CommentCard key={comment.id} style={{ transform: `rotate(${tilts[index]}deg)` }}>
//             <CommentHeader>
//               <Avatar>
//                 <img src={comment.avatar || 'https://via.placeholder.com/40'} alt={comment.username} />
//               </Avatar>
//               <div>
//                 <Username>{comment.username}</Username>
//                 <CommentTime>{comment.time}</CommentTime>
//               </div>
//             </CommentHeader>
//             <CommentBody>{comment.text}</CommentBody>
//             <CommentActions>
//               <ActionButton>
//                 <ThumbsUp size={16} /> {comment.likes}
//               </ActionButton>
//               <ActionButton>
//                 <ThumbsDown size={16} /> Reply
//               </ActionButton>
//             </CommentActions>
//             <RepliesLink>{comment.replies} replies</RepliesLink>
//           </CommentCard>
//         ))}
//       </Slider>
//     </CarouselContainer>
//   );
// };

// export default YouTubeCommentsCarousel;



// import React from 'react';
// import Slider from 'react-slick';
// import styled from 'styled-components';
// import nyTimesLogo from '../assets/nytimes.png';
// import guardianLogo from '../assets/theguardian.png';
// import leMondeLogo from '../assets/lemonde.png';
// import washingtonPostLogo from '../assets/washingtonpost.png';
// import dailyMailLogo from '../assets/dailymail.png';

// const CarouselContainer = styled.div`
//   width: 100%;
//   padding: 40px 0;
//   // background-color: #f9f9f9; /* Match the background color */
//   margin-top: 100px;
//   margin-bottom: 100px;
// `;

// const CarouselImage = styled.img`
//   width: 150px;
//   height: 150px; /* Ensure a consistent height */
//   object-fit: contain; /* Maintain aspect ratio */
//   margin: 0 auto;
//   background: transparent; /* Ensure background is transparent */
// `;

// const NewspaperCarousel = () => {
//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <CarouselContainer>
//       <Slider {...settings}>
//         <div>
//           <CarouselImage src={nyTimesLogo} alt="New York Times" />
//         </div>
//         <div>
//           <CarouselImage src={guardianLogo} alt="The Guardian" />
//         </div>
//         <div>
//           <CarouselImage src={leMondeLogo} alt="Le Monde" />
//         </div>
//         <div>
//           <CarouselImage src={washingtonPostLogo} alt="Washington Post" />
//         </div>
//         <div>
//           <CarouselImage src={dailyMailLogo} alt="Daily Mail" />
//         </div>
//       </Slider>
//     </CarouselContainer>
//   );
// };

// export default NewspaperCarousel;
