import { auth, firestore } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

export const checkUserStatus = async () => {
  const user = auth.currentUser;
  if (!user) {
    return { isLoggedIn: false, isMember: false };
  }

  const userDoc = await getDoc(doc(firestore, 'users', user.uid));
  if (userDoc.exists()) {
    const userData = userDoc.data();
    return { isLoggedIn: true, isMember: userData.isMember };
  } else {
    return { isLoggedIn: true, isMember: false };
  }
};
