import React from 'react';
import styled, { keyframes } from 'styled-components';
import { TrendingUp, Mail } from 'lucide-react';

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const HeadlineWrapper = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 1rem 0rem;
  animation: ${fadeInUp} 0.8s ease-out;

  @media (max-width: 768px) { /* Tablet */
    padding: 1.5rem 1rem;
  }

  @media (max-width: 480px) { /* Mobile */
    padding: 1rem 0.5rem;
  }
`;

const HeadlineText = styled.h1`
  font-size: 3.5rem;
  font-weight: 800;
  color: #1a1a1a;
  margin: 0 0 0.5rem;
  line-height: 1.2;
  opacity: 0;
  animation: ${fadeInUp} 0.8s ease-out forwards;
  animation-delay: ${props => props.delay || '0s'};

  span {
    color: #FF0000; // YouTube red
  }

  @media (max-width: 768px) { /* Tablet */
    font-size: 2.5rem;
  }

  @media (max-width: 480px) { /* Mobile */
    font-size: 2rem;
  }
`;

const SubheadlineText = styled.p`
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
  margin: 1rem auto 2rem; /* Changed to auto to center */
  max-width: 500px; /* Controls the width */
  text-align: center; /* Ensures text is centered */
  opacity: 0;
  animation: ${fadeInUp} 0.8s ease-out forwards;
  animation-delay: 0.6s;

  @media (max-width: 768px) { /* Tablet */
    font-size: 1rem;
    max-width: 400px; /* Adjust for tablet */
  }

  @media (max-width: 480px) { /* Mobile */
    font-size: 0.9rem;
    margin: 0.5rem auto 1.5rem; /* Changed to auto to center */
    max-width: 300px; /* Adjust for mobile */
  }
`;

const CTAButton = styled.button`
  background-color: #FF0000;
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 50px;
  margin: 2rem 0rem 0rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
  opacity: 0;
  animation: ${fadeInUp} 0.8s ease-out forwards;
  animation-delay: 1.2s;

  &:hover {
    background-color: #CC0000;
  }

  @media (max-width: 768px) { /* Tablet */
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }

  @media (max-width: 480px) { /* Mobile */
    padding: 0.7rem 1.2rem;
    font-size: 0.9rem;
  }
`;

const HeadlineComponent = ({ onGetStarted }) => {
  return (
    <HeadlineWrapper>
      <HeadlineText delay="0.2s">
        Understand your <span>YouTube</span> Viewers Better
      </HeadlineText>
      <SubheadlineText>
        When a new video is posted, we'll analyse your comments and email you the report after 24 hours
      </SubheadlineText>
      <CTAButton onClick={onGetStarted}>Get Started — $1.99 weekly</CTAButton>
    </HeadlineWrapper>
  );
};

export default HeadlineComponent;


{/* <br /> */}


// import React from 'react';
// import styled, { keyframes } from 'styled-components';
// import { TrendingUp, Mail } from 'lucide-react';

// const fadeInUp = keyframes`
//   from {
//     opacity: 0;
//     transform: translateY(20px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// `;

// const HeadlineWrapper = styled.div`
//   text-align: center;
//   max-width: 800px;
//   margin: 0 auto;
//   padding: 2rem 1rem 0rem;
//   animation: ${fadeInUp} 0.8s ease-out;
// `;

// const HeadlineText = styled.h1`
//   font-size: 3.5rem;
//   font-weight: 800;
//   color: #1a1a1a;
//   margin: 0 0 0.5rem;
//   line-height: 1.2;
//   opacity: 0;
//   animation: ${fadeInUp} 0.8s ease-out forwards;
//   animation-delay: ${props => props.delay || '0s'};

//   span {
//     color: #FF0000; // YouTube red
//   }
// `;

// const SubheadlineText = styled.p`
//   font-size: 1.1rem;
//   font-weight: 600;
//   // color: #555555;
//   color: #333;
//   margin: 1rem 0 2rem;
//   opacity: 0;
//   animation: ${fadeInUp} 0.8s ease-out forwards;
//   animation-delay: 0.6s;
// `;

// const InfoBox = styled.div`
//   display: flex;
//   align-items: center;
//   padding: 1rem;
//   border-radius: 8px;
//   margin-bottom: 1rem;
//   text-align: left;
//   opacity: 0;
//   animation: ${fadeInUp} 0.8s ease-out forwards;
// `;

// const GreenInfoBox = styled(InfoBox)`
//   background-color: #a7f3d0;
//   animation-delay: 0.8s;
// `;

// const BlueInfoBox = styled(InfoBox)`
//   background-color: #bfdbfe;
//   animation-delay: 1s;
// `;

// const InfoText = styled.p`
//   margin-left: 1rem;
//   font-size: 1rem;
//   color: #1a1a1a;
// `;

// const CTAButton = styled.button`
//   background-color: #FF0000;
//   color: white;
//   padding: 1rem 2rem;
//   border: none;
//   border-radius: 50px;
//   margin: 2rem 0rem 0rem;
//   font-size: 1.1rem;
//   font-weight: 600;
//   cursor: pointer;
//   transition: background-color 0.3s;
//   opacity: 0;
//   animation: ${fadeInUp} 0.8s ease-out forwards;
//   animation-delay: 1.2s;

//   &:hover {
//     background-color: #CC0000;
//   }
// `;

// const HeadlineComponent = ({ onGetStarted }) => {
//   return (
//     <HeadlineWrapper>
//       <HeadlineText delay="0.2s">
//         Understand your <span>YouTube</span> Viewers Better
//       </HeadlineText>
//       <SubheadlineText>
//         When a new video is posted, we'll analyse your comments<br />  and email you the report after 24hours
//       </SubheadlineText>
//       <CTAButton onClick={onGetStarted}>Get Started — $1.99 weekly</CTAButton>
//     </HeadlineWrapper>
//   );
// };

// export default HeadlineComponent;

























































// import React from 'react';
// import styled, { keyframes } from 'styled-components';
// import { TrendingUp, Mail } from 'lucide-react';

// const fadeInUp = keyframes`
//   from {
//     opacity: 0;
//     transform: translateY(20px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// `;

// const HeadlineWrapper = styled.div`
//   text-align: center;
//   max-width: 800px;
//   margin: 0 auto;
//   padding: 2rem 1rem 0rem;
//   animation: ${fadeInUp} 0.8s ease-out;
// `;

// const HeadlineText = styled.h1`
//   font-size: 3rem;
//   font-weight: 800;
//   color: #1a1a1a;
//   margin: 0 0 0.5rem;
//   line-height: 1.2;
//   opacity: 0;
//   animation: ${fadeInUp} 0.8s ease-out forwards;
//   animation-delay: ${props => props.delay || '0s'};

//   span {
//     color: #FF0000; // YouTube red
//   }
// `;

// const SubheadlineText = styled.p`
//   font-size: 1.1rem;
//   font-weight: 600;
//   // color: #555555;
//   color: #333;
//   margin: 1rem 0 2rem;
//   opacity: 0;
//   animation: ${fadeInUp} 0.8s ease-out forwards;
//   animation-delay: 0.6s;
// `;

// const InfoBox = styled.div`
//   display: flex;
//   align-items: center;
//   padding: 1rem;
//   border-radius: 8px;
//   margin-bottom: 1rem;
//   text-align: left;
//   opacity: 0;
//   animation: ${fadeInUp} 0.8s ease-out forwards;
// `;

// const GreenInfoBox = styled(InfoBox)`
//   background-color: #a7f3d0;
//   animation-delay: 0.8s;
// `;

// const BlueInfoBox = styled(InfoBox)`
//   background-color: #bfdbfe;
//   animation-delay: 1s;
// `;

// const InfoText = styled.p`
//   margin-left: 1rem;
//   font-size: 1rem;
//   color: #1a1a1a;
// `;

// const CTAButton = styled.button`
//   background-color: #FF0000;
//   color: white;
//   padding: 0.8rem 2rem;
//   border: none;
//   border-radius: 50px;
//   margin: 2rem 0rem 0rem;
//   font-size: 1.1rem;
//   font-weight: 600;
//   cursor: pointer;
//   transition: background-color 0.3s;
//   opacity: 0;
//   animation: ${fadeInUp} 0.8s ease-out forwards;
//   animation-delay: 1.2s;

//   &:hover {
//     background-color: #CC0000;
//   }
// `;

// const HeadlineComponent = () => {
//   return (
//     <HeadlineWrapper>
//       <HeadlineText delay="0.2s">
//         Understand your <span>YouTube</span> Viewers Better
//       </HeadlineText>
//       <SubheadlineText>
//         When a new video is posted, we'll analyse your comments<br />  and email you the report after 24hours
//       </SubheadlineText>
//       <CTAButton>Get Started — it's free</CTAButton>
//     </HeadlineWrapper>
//   );
// };

// export default HeadlineComponent;





      {/* <GreenInfoBox>
        <TrendingUp color="#059669" size={24} />
        <InfoText>
          Our users save an average of 43% of their time analyzing viewers
        </InfoText>
      </GreenInfoBox>
      <BlueInfoBox>
        <Mail color="#3b82f6" size={24} />
        <InfoText>
          Receive automated insights via email 24 hours after posting a video
        </InfoText>
      </BlueInfoBox> */}


// import React from 'react';
// import styled from 'styled-components';
// import { TrendingUp, Mail } from 'lucide-react';

// const HeadlineWrapper = styled.div`
//   text-align: center;
//   max-width: 800px;
//   margin: 0 auto;
//   padding: 4rem 1rem;
// `;

// const HeadlineText = styled.h1`
//   font-size: 3rem;
//   font-weight: 800;
//   color: #1a1a1a;
//   margin: 0 0 0.5rem;
//   line-height: 1.2;

//   span {
//     color: #FF0000; // YouTube red
//   }
// `;

// const SubheadlineText = styled.p`
//   font-size: 1.2rem;
//   font-weight: 400;
//   color: #656465;
//   margin: 1rem 0 2rem;
// `;

// const InfoBox = styled.div`
//   display: flex;
//   align-items: center;
//   padding: 1rem;
//   border-radius: 8px;
//   margin-bottom: 1rem;
//   text-align: left;
// `;

// const GreenInfoBox = styled(InfoBox)`
//   background-color: #a7f3d0;
// `;

// const BlueInfoBox = styled(InfoBox)`
//   background-color: #bfdbfe;
// `;

// const InfoText = styled.p`
//   margin-left: 1rem;
//   font-size: 1rem;
//   color: #1a1a1a;
// `;

// const CTAButton = styled.button`
//   background-color: #FF0000;
//   color: white;
//   padding: 0.8rem 2rem;
//   border: none;
//   border-radius: 50px;
//   font-size: 1rem;
//   font-weight: 600;
//   cursor: pointer;
//   transition: background-color 0.3s;

//   &:hover {
//     background-color: #CC0000;
//   }
// `;

// const HeadlineComponent = () => {
//   return (
//     <HeadlineWrapper>
//       <HeadlineText>
//         <span>YouTube</span> steals your time.
//       </HeadlineText>
//       <HeadlineText>
//         We'll give it back to you.
//       </HeadlineText>
//       <SubheadlineText>
//       Let AI-powered insights boost your channel's engagement while you focus on creating.
//       </SubheadlineText>
//       {/* <GreenInfoBox>
//         <TrendingUp color="#059669" size={24} />
//         <InfoText>
//           Our users save an average of 43% of their time analyzing viewers
//         </InfoText>
//       </GreenInfoBox>
//       <BlueInfoBox>
//         <Mail color="#3b82f6" size={24} />
//         <InfoText>
//           Receive automated insights via email 24 hours after posting a video
//         </InfoText>
//       </BlueInfoBox> */}
//       <CTAButton>Get Started — it's free</CTAButton>
//     </HeadlineWrapper>
//   );
// };

// export default HeadlineComponent;


// import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';
// import { TrendingUp, Mail } from 'lucide-react';

// const HeadlineWrapper = styled.div`
//   text-align: left;
//   // padding: 50px 10px;
//   max-width: 800px;
//   margin: 0 auto;
// `;

// const HeadlineText = styled.h1`
//   font-size: 2.5rem; // Reduced from 3.5rem
//   font-weight: 800;
//   color: #1a1a1a;
//   margin: 0;

//   span {
//     color: #FF0000; // YouTube red
//   }
// `;

// const SubheadlineText = styled.h2`
//   font-size: 1.25rem; // Reduced from 1.5rem
//   font-weight: 400;
//   color: #000000;
//   margin-top: 20px;
//   margin-bottom: 40px;
// `;

// const InfoBox = styled.div`
//   display: flex;
//   align-items: center;
//   padding: 15px;
//   border-radius: 8px;
//   margin-bottom: 15px;
// `;

// const GreenInfoBox = styled(InfoBox)`
//   background-color: #a7f3d0;
// `;

// const BlueInfoBox = styled(InfoBox)`
//   background-color: #bfdbfe;
// `;

// const InfoText = styled.p`
//   margin-left: 15px;
//   font-size: 1rem;
//   color: #1a1a1a;
// `;

// const BoldInfoText = styled(InfoText)`
//   font-weight: 700;
// `;

// const RegularInfoText = styled(InfoText)`
//   font-weight: 400;
// `;

// const AnimatedNumber = ({ end, duration }) => {
//   const [count, setCount] = useState(0);

//   useEffect(() => {
//     let startTime = null;
//     const animate = (timestamp) => {
//       if (!startTime) startTime = timestamp;
//       const progress = Math.min((timestamp - startTime) / duration, 1);
//       setCount(Math.floor(progress * end));
//       if (progress < 1) {
//         requestAnimationFrame(animate);
//       }
//     };
//     requestAnimationFrame(animate);
//   }, [end, duration]);

//   return <span>{count}</span>;
// };

// const HeadlineComponent = () => {
//   return (
//     <HeadlineWrapper>
//       <HeadlineText>
//         <span>YouTube</span> steals your time
//       </HeadlineText>
//       <HeadlineText>
//         We'll give it back to you
//       </HeadlineText>
//       <SubheadlineText>
//       When you post a video, you're stuck deciphering comments for hours.
//       </SubheadlineText>
//       <SubheadlineText>
//       We'll do it in seconds and tell you exactly what to do next.
//       </SubheadlineText>
//       <GreenInfoBox>
//         <TrendingUp color="#059669" size={24} />
//         <BoldInfoText>
//         Our users save an average of <AnimatedNumber end={43} duration={2000} />% of their time analysing viewers
//         </BoldInfoText>
//       </GreenInfoBox>
//       <BlueInfoBox>
//         <Mail color="#3b82f6" size={24} />
//         <RegularInfoText>
//           Receive automated insights via email 24 hours after posting a video
//         </RegularInfoText>
//       </BlueInfoBox>
//     </HeadlineWrapper>
//   );
// };

// export default HeadlineComponent;



