import React from 'react';
import styled from 'styled-components';
import { Rocket } from 'lucide-react'; // Ensure Lucide Icons is installed

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px 20px;
  background-color: #fff; /* Adjust if you need a different background */
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: 768px) { /* Tablet */
    padding: 30px 15px;
    max-width: 90%;
  }

  @media (max-width: 480px) { /* Mobile */
    padding: 20px 10px;
    max-width: 95%;
  }
`;

const Stars = styled.div`
  margin-bottom: 20px;
  color: #ffd700; /* Gold color for stars */
  font-size: 1.8rem; /* Increased size for more prominence */

  @media (max-width: 480px) { /* Mobile */
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
`;

const Headline = styled.h2`
  font-size: 2rem; /* Increased size */
  font-weight: 800; /* Bold weight for a stronger impact */
  color: #1a1a1a; /* Dark color for headline */
  margin-bottom: 20px;

  @media (max-width: 768px) { /* Tablet */
    font-size: 2rem;
  }

  @media (max-width: 480px) { /* Mobile */
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
`;

const Subheadline = styled.p`
  font-size: 1.25rem; /* Slightly larger font */
  color: #333; /* Darker shade for more contrast */
  font-weight: 500; /* Bold weight */
  margin-bottom: 30px;
  max-width: 600px;

  @media (max-width: 768px) { /* Tablet */
    font-size: 1.1rem;
    margin-bottom: 20px;
  }

  @media (max-width: 480px) { /* Mobile */
    font-size: 1rem;
    margin-bottom: 15px;
    max-width: 100%;
  }
`;

const CTA = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 1.2rem;
  color: #ff4b4b;
  font-weight: 800;
  max-width: 100%;
  text-align: middle;
`;

const CTAIcon = styled(Rocket)`
  margin-right: 8px;
  color: #ff4b4b;
  flex-shrink: 0;
`;

const CTAText = styled.span`
  display: inline;
`;

const CommentAnalysisComponent = () => {
  return (
    <Container>
      <Stars>
        ⭐⭐⭐⭐⭐
      </Stars>
      <Headline>
        "YouTubers save 49% of their time with Luminate's comment analysis"
      </Headline>
      <Subheadline>
        Don't let manual comment review steal your creative time. Boost your productivity and viewer engagement with our AI-powered insights.
      </Subheadline>
      <CTA>
        <CTAIcon size={24} />
        <CTAText>Join 10,286 creators growing their channels right now</CTAText>
      </CTA>
    </Container>
  );
};

export default CommentAnalysisComponent;

// import React from 'react';
// import styled from 'styled-components';
// import { Rocket } from 'lucide-react'; // Ensure Lucide Icons is installed

// // Styled Components
// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   text-align: center;
//   padding: 40px 20px;
//   background-color: #fff; /* Adjust if you need a different background */
//   border-radius: 8px;
//   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
//   max-width: 1000px;
//   margin: 0 auto;
// `;

// const Stars = styled.div`
//   margin-bottom: 20px;
//   color: #ffd700; /* Gold color for stars */
//   font-size: 1.8rem; /* Increased size for more prominence */
// `;

// const Headline = styled.h2`
//   font-size: 2.5rem; /* Increased size */
//   font-weight: 900; /* Bold weight for a stronger impact */
//   color: #1a1a1a; /* Dark color for headline */
//   margin-bottom: 20px;
// `;

// const Subheadline = styled.p`
//   font-size: 1.25rem; /* Slightly larger font */
//   color: #333; /* Darker shade for more contrast */
//   font-weight: 700; /* Bold weight */
//   margin-bottom: 30px;
//   max-width: 600px;
// `;

// const CTA = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 1.2rem; /* Increased size for emphasis */
//   color: #ff4b4b; /* Red color for CTA */
//   font-weight: 800; /* Bolder weight */
// `;

// const CTAIcon = styled(Rocket)`
//   margin-right: 8px;
//   color: #ff4b4b; /* Red color for rocket icon */
// `;

// const CommentAnalysisComponent = () => {
//   return (
//     <Container>
//       <Stars>
//         ⭐⭐⭐⭐⭐
//       </Stars>
//       <Headline>
//         "YouTubers save 49% of their time with Luminate's comment analysis"
//       </Headline>
//       <Subheadline>
//         Don't let manual comment review steal your creative time. Boost your productivity and viewer engagement with our AI-powered insights.
//       </Subheadline>
//       <CTA>
//         <CTAIcon size={24} />
//         Join 10,286 creators growing their channels right now
//       </CTA>
//     </Container>
//   );
// };

// export default CommentAnalysisComponent;












































// import React from 'react';
// import styled from 'styled-components';

// const TrustedByContainer = styled.div`
//   text-align: center;
//   margin-top: 200px;

// `;

// const TrustedByText = styled.p`
//   font-size: 2rem;
//   font-weight: bold;
//   color: #333;

//   span {
//     // color: #007BFF; /* Same color as the blue button */
//     color: #ff0100;
//   }
// `;


// // const TrustedByText = styled.h2`
// //   font-size: 1.5rem; /* Slightly smaller for subheadline */
// //   font-weight: normal;
// //   color: #6c6c6c; /* Lighter color for subheadline */
// //   margin-top: 20px; /* Margin for spacing */

// //   span {
// //     color: #007BFF; /* Same color as the blue button */
// //   }

// // `;


// const TrustedByComponent = () => (
//   <TrustedByContainer>
//     <TrustedByText>Trusted by <span>10,000+</span> YouTubers worldwide</TrustedByText>
//   </TrustedByContainer>
// );

// export default TrustedByComponent;
