// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import UpdateMembershipStatus from './pages/UpdateMembershipStatus';
import LoginPage from './pages/LoginPage';
import ContactPage from './pages/ContactPage';
import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import AccountSettingsPage from './pages/AccountSettingsPage';
import './App.css';
import styled from 'styled-components';

const AppContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const App = () => (
  <Router>
    <HeaderComponent />
    <AppContainer>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/update-membership-status" element={<UpdateMembershipStatus />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/account-settings" element={<AccountSettingsPage />} />
      </Routes>
    </AppContainer>
    <FooterComponent />
  </Router>
);

export default App;
