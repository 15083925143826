// import React, { useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { doc, updateDoc, getDoc } from 'firebase/firestore';
// import { firestore } from '../firebase';
// import styled from 'styled-components';

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   min-height: 100vh;
//   background-color: #f9f9f9;
//   color: #333;
//   padding: 40px 20px;
// `;

// const Message = styled.p`
//   font-size: 1.5rem;
//   color: #6c6c6c;
// `;

// const UpdateMembershipStatus = () => {
//   const navigate = useNavigate();
//   const location = useLocation();

//   useEffect(() => {
//     const updateMembershipStatus = async () => {
//       const params = new URLSearchParams(location.search);
//       const userId = params.get('session_id'); // Using session_id as userId for consistency

//       if (userId) {
//         try {
//           // Fetch user data from Firestore
//           const userDoc = await getDoc(doc(firestore, 'users', userId));
//           if (userDoc.exists()) {
//             const userData = userDoc.data();

//             // Update user document to set isMember to true
//             await updateDoc(doc(firestore, 'users', userId), {
//               isMember: true,
//             });

//             console.log('User membership status updated to isMember: true');

//             // Redirect or trigger other actions after updating
//             navigate('/home');
//           } else {
//             console.error('User document not found.');
//           }
//         } catch (error) {
//           console.error('Error updating membership status:', error);
//         }
//       }
//     };

//     updateMembershipStatus();
//   }, [location, navigate]);

//   return (
//     <Container>
//       <Message>Updating Membership Status...</Message>
//     </Container>
//   );
// };

// export default UpdateMembershipStatus;




///// old code for Stripe BELOW

import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import axios from 'axios';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f9f9f9; /* Updated background color */
  color: #333; /* Updated text color */
  padding: 40px 20px;
`;

const Message = styled.p`
  font-size: 1.5rem;
  color: #6c6c6c;
`;

const UpdateMembershipStatus = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const updateMembershipStatus = async () => {
      const params = new URLSearchParams(location.search);
      const sessionId = params.get('session_id');
      
      if (sessionId) {
        try {
          // Fetch session data from your backend or directly from Stripe if you have the session endpoint
          const response = await axios.get(`https://us-central1-youtubecommentssummary.cloudfunctions.net/getSessionData?session_id=${sessionId}`);
          const session = response.data;

          if (session && session.metadata && session.metadata.userId) {
            // const userId = session.metadata.userId;
            // await updateDoc(doc(firestore, 'users', userId), {
            //   isMember: true,
            // });

            navigate('/home');
          } else {
            console.error('User ID is missing in the session metadata.');
          }
        } catch (error) {
          console.error('Error updating membership status:', error);
        }
      }
    };

    updateMembershipStatus();
  }, [location, navigate]);

  return (
    <Container>
      <Message>Updating Membership Status...</Message>
    </Container>
  );
};

export default UpdateMembershipStatus;
