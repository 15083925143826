import React, { useState } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { marked } from 'marked';

const TryItOutContainer = styled.div`
  // background-color: #f9f9f9;
  padding: 40px 20px;
  text-align: center;
  border-top: 2px solid #ddd;
  margin-top: 50px;
`;

const TryItOutTitle = styled.h3`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto 20px;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px;
  width: 100%;
  border: 1.5px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  outline: none;

  &:focus {
    border-color: #ff0100;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #ff0100;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
  white-space: nowrap;

  &:hover {
    background-color: #dd0100;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  text-align: left;
  margin-top: 5px;
  width: 100%;
  padding-left: 5px;
`;

const ProcessingText = styled.div`
  font-size: 1rem;
  color: #333;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.div`
  border: 3px solid #f3f3f3;
  border-top: 3px solid #ff0100;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-left: 10px;
  display: inline-block;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ResultContainer = styled.div`
  font-size: 1rem;
  color: #333;
  margin-top: 20px;
  text-align: left;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const VideoPlayer = styled(ReactPlayer)`
  margin: 20px 0;
  border-radius: 10px;
  overflow: hidden;
`;

const TryItOutComponent = () => {
  const [youtubeLink, setYoutubeLink] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [result, setResult] = useState('');
  const [videoId, setVideoId] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    setYoutubeLink(e.target.value);
    setErrorMessage('');
  };

  const extractVideoId = (url) => {
    const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
    return match ? match[1] : null;
  };

  const handleSubmit = async () => {
    if (youtubeLink.trim() === '') {
      setErrorMessage('Please enter a YouTube link.');
      return;
    }

    const extractedVideoId = extractVideoId(youtubeLink);

    if (!extractedVideoId) {
      setErrorMessage('Invalid YouTube link. Please try again.');
      return;
    }

    setIsProcessing(true);
    setErrorMessage('');

    try {
      const response = await fetch('https://us-central1-youtubecommentssummary.cloudfunctions.net/tryItOut', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ youtubeLink }),
      });

      const data = await response.json();

      const htmlResult = marked(data.summary || 'No summary available');
      setVideoId(extractedVideoId);
      setVideoTitle(data.videoTitle || 'Untitled Video');
      setIsProcessing(false);
      setResult(htmlResult);
    } catch (error) {
      console.error('Error fetching summary:', error);
      setIsProcessing(false);
      setResult('');
      setErrorMessage('There was an error processing your request. Please try again.');
    }
  };

  return (
    <TryItOutContainer>
      <TryItOutTitle>Try it out!</TryItOutTitle>
      {!isProcessing && !result && (
        <InputContainer>
          <InputWrapper>
            <Input
              type="text"
              placeholder="Enter a YouTube link"
              value={youtubeLink}
              onChange={handleChange}
            />
            <Button onClick={handleSubmit}>Enter</Button>
          </InputWrapper>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </InputContainer>
      )}
      {isProcessing && (
        <ProcessingText>
          Processing...
          <LoadingSpinner />
        </ProcessingText>
      )}
      {result && (
        <ResultContainer>
          <VideoPlayer 
            url={`https://www.youtube.com/watch?v=${videoId}`} 
            controls
            width="100%"
            height="400px"
          />
          <div dangerouslySetInnerHTML={{ __html: result }} />
        </ResultContainer>
      )}
    </TryItOutContainer>
  );
};

export default TryItOutComponent;
