import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, firestore } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import styled from 'styled-components';
import axios from 'axios';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
  color: #333;
  padding: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Label = styled.label`
  color: #333;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border: 2px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  background-color: #fff;
  color: #333;
  outline: none;

  &:focus {
    border-color: #FF0100;
  }
`;

const Button = styled.button`
  padding: 15px 20px;
  background-color: #FF0100;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;

  &:hover {
    background-color: #DD0100;
  }
`;

const TextButton = styled.button`
  background: none;
  border: none;
  // color: #007BFF;
  color: #555555;
  cursor: pointer;
  font-size: 16px;
  margin-top: 30px;
  text-decoration: underline;

  &:hover {
    color: #0056b3;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: -10px;
  margin-bottom: 10px;
`;

const AccountSettingsPage = () => {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({ email: '', youtubeChannel: '' });
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [youtubeChannelError, setYoutubeChannelError] = useState('');
  const [isSubscribedToEmail, setIsSubscribedToEmail] = useState(true); // State for email subscription
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(firestore, 'users', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setFormData({
              email: userData.email || '',
              youtubeChannel: '@' + userData.youtubeChannel || '',
            });
            setIsSubscribedToEmail(userData.isSubscribedToEmail !== false); // If field doesn't exist or is true, user is subscribed
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        } finally {
          setLoading(false);
        }
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleToggleSubscription = async () => {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error('User not authenticated');

      const newSubscriptionStatus = !isSubscribedToEmail; // Toggle subscription status
      const userRef = doc(firestore, 'users', user.uid);

      await updateDoc(userRef, {
        isSubscribedToEmail: newSubscriptionStatus,
      });

      setIsSubscribedToEmail(newSubscriptionStatus); // Update state
      alert(`You have successfully ${newSubscriptionStatus ? 'subscribed to' : 'unsubscribed from'} emails.`);
    } catch (error) {
      console.error('Error updating subscription status:', error);
      alert('Failed to update subscription status. Please try again later.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === 'email') setEmailError('');
    if (name === 'youtubeChannel') setYoutubeChannelError('');
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateYoutubeChannel = async (channelHandle) => {
    const youtubeApiKey = 'AIzaSyDrvc1bhX95Y4ViJc12c_bd0mkmI3DJEfQ';
    const url = `https://www.googleapis.com/youtube/v3/channels?part=id&forHandle=${channelHandle}&key=${youtubeApiKey}`;  
    try {
      const response = await axios.get(url);
      return response.data.items && response.data.items.length > 0;
    } catch (error) {
      console.error("Error checking YouTube channel: ", error);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;

    if (!validateEmail(formData.email)) {
      setEmailError('Please enter a valid email address');
      isValid = false;
    }

    const channelName = formData.youtubeChannel.startsWith('@') ? formData.youtubeChannel.substring(1) : formData.youtubeChannel;
    const isYoutubeChannelValid = await validateYoutubeChannel(channelName);

    if (!isYoutubeChannelValid) {
      setYoutubeChannelError('YouTube channel does not exist');
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    try {
      const response = await axios.post('https://us-central1-youtubecommentssummary.cloudfunctions.net/updateUserSettings', {
        userId: auth.currentUser.uid,
        email: formData.email,
        youtubeChannel: channelName,
      });

      if (response.status === 200) {
        navigate('/home');
      } else {
        setError('Error updating user settings');
      }
    } catch (error) {
      console.error('Error updating user settings:', error);
      setError('Error updating user settings');
    }
  };

  // const handleCancelSubscription = async () => {
  //   try {
  //     const user = auth.currentUser;
  //     setLoading(true);
  //     if (user) {
  //       const response = await axios.post(
  //         'https://us-central1-youtubecommentssummary.cloudfunctions.net/cancelSubscription',
  //         { userId: user.uid },
  //         { withCredentials: true }
  //       );

  //       if (response.status === 200) {
  //         setLoading(false);
  //         alert('Subscription cancellation initiated successfully.');
  //         navigate('/home');
  //       } else {
  //         setLoading(false);
  //         throw new Error('Failed to initiate subscription cancellation');
  //       }
  //     } else {
  //       setLoading(false);
  //       throw new Error('User not authenticated');
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.error('Error cancelling subscription:', error);
  //     setError(error.message || 'Error cancelling subscription');
  //   }
  // };

  ///// For Stripe BELOW

  const handleCancelSubscription = async () => {
    try {
      const user = auth.currentUser;
      setLoading(true);
      if (user) {
        const userDoc = await getDoc(doc(firestore, 'users', user.uid));
        const stripeSubscriptionId = userDoc.data().stripeSubscriptionId;

        if (!stripeSubscriptionId) {
          throw new Error('No active subscription found');
        }

        const response = await axios.post('https://us-central1-youtubecommentssummary.cloudfunctions.net/cancelSubscription',
          { stripeSubscriptionId, userId: user.uid },
          { withCredentials: true }
        );

        if (response.status === 200) {
          setLoading(false);
          alert('Subscription cancellation initiated successfully. Your subscription will be canceled at the end of the billing period.');
          navigate('/home');
        } else {
          setLoading(false);
          throw new Error('Failed to initiate subscription cancellation');
        }
      } else {
        setLoading(false);
        throw new Error('User not authenticated');
      }
    } catch (error) {
      setLoading(false);
      console.error('Error cancelling subscription:', error);
      setError(error.message || 'Error cancelling subscription');
    }
  };

  if (loading) {
    return <Container>Loading...</Container>;
  }

  return (
    <Container>
      <h2>Account Settings</h2>
      <Form onSubmit={handleSubmit}>
      
        <Label htmlFor="email">Email</Label>
        <Input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
        {emailError && <ErrorMessage>{emailError}</ErrorMessage>}

        <Label htmlFor="youtubeChannel">YouTube Channel</Label>
        <Input type="text" id="youtubeChannel" name="youtubeChannel" value={formData.youtubeChannel} onChange={handleChange} required />
        {youtubeChannelError && <ErrorMessage>{youtubeChannelError}</ErrorMessage>}

        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Button type="submit">Save</Button>
      </Form>
      <Button onClick={handleCancelSubscription} disabled={loading}>
        Cancel Subscription
      </Button>
      <TextButton onClick={handleToggleSubscription} disabled={loading}>
        {isSubscribedToEmail ? 'Unsubscribe from Emails' : 'Subscribe to Emails'}
      </TextButton>
    </Container>
  );
};

export default AccountSettingsPage;



