// src/firebase.js
// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics'; // Import Firebase Analytics

const firebaseConfig = {
  apiKey: "AIzaSyDrvc1bhX95Y4ViJc12c_bd0mkmI3DJEfQ",
  authDomain: "commentssummary.firebaseapp.com",
  projectId: "youtubecommentssummary",
  storageBucket: "youtubecommentssummary.appspot.com",
  messagingSenderId: "275048065943",
  appId: "1:275048065943:web:f7a4b115e0bd7d8cee32d2",
  measurementId: "G-0XV2KPVXP9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const analytics = getAnalytics(app); // Initialize Analytics

// Set auth persistence
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log('Auth persistence set to local');
  })
  .catch((error) => {
    console.error('Error setting auth persistence:', error);
  });

export { auth, firestore, analytics };


