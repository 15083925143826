import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import FormComponent from '../components/FormComponent';
import StepsComponent from '../components/StepsComponent';
import TrustedByComponent from '../components/TrustedByComponent';
import HeadlineComponent from '../components/HeadlineComponent';
import NewspaperCarousel from '../components/NewspaperCarousel';
import { checkUserStatus } from '../utils/authUtils';
import { auth, firestore } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, orderBy, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { marked } from 'marked';
import ReactPlayer from 'react-player';
import '../App.css';
import TryItOutComponent from '../components/TryItOutComponent';
import { Users } from 'lucide-react'; // Importing the Users icon

// Keyframes for animations
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Styled-components for the modal and form
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 0.5s ease-out;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background-color: #ffffff;
  padding: 40px;
  border-radius: 30px;
  max-width: 500px;
  width: 90%;
  animation: ${slideUp} 0.5s ease-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 480px) { /* Mobile */
    padding: 20px;
    width: 95%;
  }
`;

const Container = styled.div`
  padding: 40px;
  text-align: center;
  background-color: #FDFBFD;
  min-height: 100vh;
  width: 100%;

  @media (max-width: 768px) { /* Tablet */
    padding: 30px;
  }

  @media (max-width: 480px) { /* Mobile */
    padding: 20px;
  }
`;

const DashboardTitle = styled.h2`
  color: #333;
  font-size: 2.8rem;
  margin-top: 30px;
  margin-bottom: 40px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;

  @media (max-width: 480px) { /* Mobile */
    font-size: 2rem;
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;

const PaymentMessage = styled.div`
  color: #333;
  font-size: 1.8rem;
  margin-top: 225px;
  margin-bottom: 30px;
  font-weight: 500;

  @media (max-width: 480px) { /* Mobile */
    font-size: 1.4rem;
    margin-top: 150px;
    margin-bottom: 20px;
  }
`;

const StyledButton = styled.button`
  padding: 15px 30px;
  background-color: #ff0100;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  margin-top: 30px;
  transition: all 0.3s ease;
  margin-bottom: 200px;

  &:hover {
    background-color: #dd0100;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 480px) { /* Mobile */
    padding: 12px 25px;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 150px;
  }
`;

const VideoList = styled.ul`
  list-style-type: none;
  padding: 0;
  max-width: 900px;
  margin: 0 auto;

  @media (max-width: 480px) { /* Mobile */
    padding: 0 15px;
  }
`;

const VideoListItem = styled.li`
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  padding: 20px;
  margin: 20px 0;
  text-align: left;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
  }

  @media (max-width: 480px) { /* Mobile */
    padding: 15px;
    margin: 15px 0;
  }
`;

const VideoTitle = styled.h3`
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 15px;

  @media (max-width: 480px) { /* Mobile */
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
`;

const VideoPlayer = styled(ReactPlayer)`
  margin: 20px 0;
  border-radius: 10px;
  overflow: hidden;

  @media (max-width: 480px) { /* Mobile */
    width: 100%;
    height: 200px;
  }
`;

const SummaryText = styled.div`
  font-size: 1.1rem;
  line-height: 1.6;
  color: #444;
  margin: 20px 0;

  @media (max-width: 480px) { /* Mobile */
    font-size: 1rem;
    margin: 15px 0;
  }
`;

const MetaInfo = styled.p`
  font-size: 1rem;
  color: #666;
  margin: 10px 0;

  @media (max-width: 480px) { /* Mobile */
    font-size: 0.9rem;
  }
`;

const LoadingSpinner = styled.div`
  border: 3px solid #f3f3f3;
  border-top: 3px solid #ff0100;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-left: 10px;
  display: inline-block;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @media (max-width: 480px) { /* Mobile */
    width: 18px;
    height: 18px;
  }
`;

const HomePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [summaries, setSummaries] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDoc = await checkUserStatus();
        setIsLoggedIn(true);
        setIsMember(userDoc.isMember);
        if (userDoc.isMember) {
          const q = query(
            collection(firestore, 'summaries'), 
            where('userId', '==', user.uid),           
            orderBy('publishedTime', 'desc')
          );

          onSnapshot(q, (querySnapshot) => {
            const summariesList = querySnapshot.docs.map(doc => {
              const data = doc.data();
              return {
                id: doc.id,
                ...data,
                summary: marked(data.summary),
                timestamp: data.timestamp ? format(data.timestamp.toDate(), 'PPpp') : 'N/A',
                publishedTime: data.publishedTime && data.publishedTime !== '' ? data.publishedTime : null
              };
            });
            setSummaries(summariesList);
          });
        }
      } else {
        setIsLoggedIn(false);
        setIsMember(false);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handlePayment = async () => {
    try {
      setIsProcessing(true);
      const user = auth.currentUser;
      const response = await axios.post('https://us-central1-youtubecommentssummary.cloudfunctions.net/createCheckoutSession', {
        email: user.email,
        userId: user.uid
      });

      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error initiating Stripe checkout: ', error);
      setIsProcessing(false);
    }
  };

  if (isLoading) {
    return <Container><LoadingSpinner /></Container>;
  }

  return (
    <Container>
      {isModalOpen && (
        <Overlay>
          <ModalContainer>
            <FormComponent setIsProcessing={setIsProcessing} onClose={() => setIsModalOpen(false)} />
          </ModalContainer>
        </Overlay>
      )}

      {!isLoggedIn ? (
        <>
          <div style={{ margin: '30px 0px 250px 0' }}>
            <HeadlineComponent onGetStarted={() => setIsModalOpen(true)} /> {/* Pass function to open modal */}
            <NewspaperCarousel />
          </div>
          <div style={{ margin: '0px 0px 250px 0' }}>
            <StepsComponent />
          </div>
          <div style={{ margin: '0px 0px 300px 0' }}>
            <TrustedByComponent />
          </div>
          <TryItOutComponent />
        </>
      ) : isMember ? (
        <>
          <DashboardTitle>Your Channel Insights</DashboardTitle>
          {summaries.length === 0 ? (
            <PaymentMessage>
              Processing latest video...
              <LoadingSpinner />
            </PaymentMessage>
          ) : (
            <VideoList>
              {summaries.map(summary => (
                <VideoListItem key={summary.id}>
                  <VideoTitle dangerouslySetInnerHTML={{ __html: summary.videoTitle }}/>
                  <VideoPlayer 
                    url={`https://www.youtube.com/watch?v=${summary.videoId}`} 
                    controls
                    width="100%"
                    height="400px"
                  />
                  <SummaryText dangerouslySetInnerHTML={{ __html: summary.summary }} />
                  <MetaInfo>Channel: {summary.youtubeChannel}</MetaInfo>
                  <MetaInfo>Published on: {
                    summary.publishedTime ? 
                      (() => {
                        try {
                          return format(parseISO(summary.publishedTime), 'MMMM do, yyyy');
                        } catch (error) {
                          console.error('Error formatting date:', error, summary.publishedTime);
                          return 'Invalid Date';
                        }
                      })() 
                      : 'N/A'
                  }</MetaInfo>
                </VideoListItem>
              ))}
            </VideoList>
          )}
        </>
      ) : (
        <>
          <DashboardTitle>Your Channel Insights</DashboardTitle>
          <PaymentMessage>Unlock your YouTube channel insights</PaymentMessage>
          <StyledButton onClick={handlePayment} disabled={isProcessing}>
            {isProcessing ? (
              <>
                Processing
                <LoadingSpinner />
              </>
            ) : (
              'Unlock your Audience Insights ($1.99 weekly) ✨'
            )}
          </StyledButton>
        </>
      )}
    </Container>
  );
};

export default HomePage;





// import React, { useEffect, useState } from 'react';
// import styled, { keyframes } from 'styled-components';
// import FormComponent from '../components/FormComponent';
// import StepsComponent from '../components/StepsComponent';
// import TrustedByComponent from '../components/TrustedByComponent';
// import HeadlineComponent from '../components/HeadlineComponent';
// import NewspaperCarousel from '../components/NewspaperCarousel';
// import { checkUserStatus } from '../utils/authUtils';
// import { auth, firestore } from '../firebase';
// import { onAuthStateChanged } from 'firebase/auth';
// import { collection, query, where, orderBy, onSnapshot, doc, updateDoc } from 'firebase/firestore';
// import axios from 'axios';
// import { format, parseISO } from 'date-fns';
// import { marked } from 'marked';
// import ReactPlayer from 'react-player';
// import '../App.css';
// import TryItOutComponent from '../components/TryItOutComponent';
// import { Users } from 'lucide-react'; // Importing the Users icon

// // Keyframes for animations
// const fadeIn = keyframes`
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// `;

// const slideUp = keyframes`
//   from {
//     transform: translateY(100px);
//     opacity: 0;
//   }
//   to {
//     transform: translateY(0);
//     opacity: 1;
//   }
// `;

// // Styled-components for the modal and form
// const Overlay = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0, 0, 0, 0.5);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   animation: ${fadeIn} 0.5s ease-out;
//   z-index: 1000; /* Ensures the overlay is above other elements */
// `;

// const ModalContainer = styled.div`
//   background-color: #ffffff;
//   padding: 40px;
//   border-radius: 30px;
//   max-width: 500px;
//   width: 90%;
//   animation: ${slideUp} 0.5s ease-out;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
// `;

// const Form = styled.form`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
// `;

// const Input = styled.input`
//   padding: 15px;
//   margin-bottom: 10px;
//   border: 1.5px solid #ddd;
//   border-radius: 5px;
//   font-size: 16px;
//   background-color: #fff;
//   color: #333;
//   outline: none;
//   width: 100%;

//   &:focus {
//     border-color: #ff0100;
//   }
// `;

// const Button = styled.button`
//   padding: 15px 20px;
//   background-color: #ee0100;
//   color: #fff;
//   border: none;
//   border-radius: 50px;
//   cursor: pointer;
//   font-size: 18px;
//   font-weight: bold;
//   margin-top: 20px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;

//   &:hover {
//     background-color: #dd0100;
//   }
// `;

// const InfoContainer = styled.div`
//   display: flex;
//   align-items: flex-start;
//   font-size: 14px;
//   color: #222222;
//   margin-top: 5px;
//   width: 100%;
//   text-align: left;

//   & > svg {
//     margin-right: 5px;
//     flex-shrink: 0;
//   }

//   span {
//     display: inline;
//     flex: 1;
//   }
// `;

// const Container = styled.div`
//   padding: 40px;
//   text-align: center;
//   // background-color: #f9f9f9;
//   // background-color: #fdfdfd;
//   background-color: #FDFBFD;
//   // background-color: #fffbf4;
//   // background-color: #fff9ef;
//   min-height: 100vh;
//   width: 100%;
// `;

// const DashboardTitle = styled.h2`
//   color: #333;
//   font-size: 2.8rem;
//   margin-top: 30px;
//   margin-bottom: 40px;
//   font-weight: 700;
//   text-transform: uppercase;
//   letter-spacing: 2px;
// `;

// const PaymentMessage = styled.div`
//   color: #333;
//   font-size: 1.8rem;
//   margin-top: 225px;
//   margin-bottom: 30px;
//   font-weight: 500;
// `;

// const StyledButton = styled.button`
//   padding: 15px 30px;
//   // background-color: #007BFF;
//   background-color: #ff0100;
//   color: #fff;
//   border: none;
//   border-radius: 50px;
//   cursor: pointer;
//   font-size: 18px;
//   font-weight: bold;
//   margin-top: 30px;
//   transition: all 0.3s ease;
//   margin-bottom: 200px;

//   &:hover {
//     // background-color: #0056b3;
//     background-color: #dd0100;
//     transform: translateY(-2px);
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   }
// `;

// const VideoList = styled.ul`
//   list-style-type: none;
//   padding: 0;
//   max-width: 900px;
//   margin: 0 auto;
// `;

// const VideoListItem = styled.li`
//   background-color: #fff;
//   border: 1px solid #e0e0e0;
//   border-radius: 15px;
//   padding: 30px;
//   margin: 30px 0;
//   text-align: left;
//   box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
//   transition: all 0.3s ease;

//   &:hover {
//     transform: translateY(-5px);
//     box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
//   }
// `;

// const VideoTitle = styled.h3`
//   font-size: 1.8rem;
//   color: #333;
//   margin-bottom: 15px;
// `;

// const VideoPlayer = styled(ReactPlayer)`
//   margin: 20px 0;
//   border-radius: 10px;
//   overflow: hidden;
// `;

// const SummaryText = styled.div`
//   font-size: 1.1rem;
//   line-height: 1.6;
//   color: #444;
//   margin: 20px 0;
// `;

// const MetaInfo = styled.p`
//   font-size: 1rem;
//   color: #666;
//   margin: 10px 0;
// `;

// const LoadingSpinner = styled.div`
//   border: 3px solid #f3f3f3;
//   border-top: 3px solid #ff0100;
//   border-radius: 50%;
//   width: 20px;
//   height: 20px;
//   animation: spin 1s linear infinite;
//   margin-left: 10px;
//   display: inline-block;

//   @keyframes spin {
//     0% { transform: rotate(0deg); }
//     100% { transform: rotate(360deg); }
//   }
// `;

// const HomePage = () => {
//   const [isLoading, setIsLoading] = useState(true);
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [isMember, setIsMember] = useState(false);
//   const [summaries, setSummaries] = useState([]);
//   const [isProcessing, setIsProcessing] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, async (user) => {
//       if (user) {
//         const userDoc = await checkUserStatus();
//         setIsLoggedIn(true);
//         setIsMember(userDoc.isMember);
//         if (userDoc.isMember) {
//           const q = query(
//             collection(firestore, 'summaries'), 
//             where('userId', '==', user.uid),           
//             orderBy('publishedTime', 'desc')
//           );

//           onSnapshot(q, (querySnapshot) => {
//             const summariesList = querySnapshot.docs.map(doc => {
//               const data = doc.data();
//               return {
//                 id: doc.id,
//                 ...data,
//                 summary: marked(data.summary),
//                 timestamp: data.timestamp ? format(data.timestamp.toDate(), 'PPpp') : 'N/A',
//                 publishedTime: data.publishedTime && data.publishedTime !== '' ? data.publishedTime : null
//               };
//             });
//             setSummaries(summariesList);
//           });
//         }
//       } else {
//         setIsLoggedIn(false);
//         setIsMember(false);
//       }
//       setIsLoading(false);
//     });

//     return () => unsubscribe();
//   }, []);

//   // const handlePayment = async () => {
//   //   try {
//   //     setIsProcessing(true);
//   //     const user = auth.currentUser;
  
//   //     // Call the new function to activate the user without Stripe
//   //     const response = await axios.post('https://us-central1-youtubecommentssummary.cloudfunctions.net/activateUserWithoutStripe', {
//   //       userId: user.uid,
//   //     });
  
//   //     console.log('User activated without Stripe payment');
  
//   //     // If the response is successful, directly update Firestore here
//   //     if (response.status === 200) {
//   //       await updateDoc(doc(firestore, 'users', user.uid), {
//   //         isMember: true,
//   //       });
  
//   //       console.log('User membership status updated to isMember: true');
  
//   //       // Use React Router's navigate function to redirect to the home page
//   //       window.location.href = '/home';  // Make sure this path exists and is correct
//   //     } else {
//   //       throw new Error('Error activating user');
//   //     }
//   //   } catch (error) {
//   //     console.error('Error activating user or updating membership status: ', error);
//   //     setIsProcessing(false); // Reset processing state if there's an error
//   //   }
//   // };

//   //// For Stripe BELOW
//   const handlePayment = async () => {
//     try {
//       setIsProcessing(true);
//       const user = auth.currentUser;
//       const response = await axios.post('https://us-central1-youtubecommentssummary.cloudfunctions.net/createCheckoutSession', {
//         email: user.email,
//         userId: user.uid
//       });

//       window.location.href = response.data.url;
//     } catch (error) {
//       console.error('Error initiating Stripe checkout: ', error);
//       setIsProcessing(false);
//     }
//   };

//   if (isLoading) {
//     return <Container><LoadingSpinner /></Container>;
//   }

//   return (
//     <Container>
//       {isModalOpen && (
//         <Overlay>
//           <ModalContainer>
//             <FormComponent setIsProcessing={setIsProcessing} onClose={() => setIsModalOpen(false)} />
//           </ModalContainer>
//         </Overlay>
//       )}

//       {!isLoggedIn ? (
//         <>
//           <div style={{ margin: '30px 0px 350px 0' }}>
//             <HeadlineComponent onGetStarted={() => setIsModalOpen(true)} /> {/* Pass function to open modal */}
//             <NewspaperCarousel />
//           </div>
//           <div style={{ margin: '0px 0px 300px 0' }}>
//             <StepsComponent />
//           </div>
//           <div style={{ margin: '0px 0px 300px 0' }}>
//             <TrustedByComponent />
//           </div>
//           <TryItOutComponent />
//         </>
//       ) : isMember ? (
//         <>
//           <DashboardTitle>Your Channel Insights</DashboardTitle>
//           {summaries.length === 0 ? (
//             <PaymentMessage>
//               Processing latest video...
//               <LoadingSpinner />
//             </PaymentMessage>
//           ) : (
//             <VideoList>
//               {summaries.map(summary => (
//                 <VideoListItem key={summary.id}>
//                   <VideoTitle dangerouslySetInnerHTML={{ __html: summary.videoTitle }}/>
//                   <VideoPlayer 
//                     url={`https://www.youtube.com/watch?v=${summary.videoId}`} 
//                     controls
//                     width="100%"
//                     height="400px"
//                   />
//                   <SummaryText dangerouslySetInnerHTML={{ __html: summary.summary }} />
//                   <MetaInfo>Channel: {summary.youtubeChannel}</MetaInfo>
//                   <MetaInfo>Published on: {
//                     summary.publishedTime ? 
//                       (() => {
//                         try {
//                           return format(parseISO(summary.publishedTime), 'MMMM do, yyyy');
//                         } catch (error) {
//                           console.error('Error formatting date:', error, summary.publishedTime);
//                           return 'Invalid Date';
//                         }
//                       })() 
//                       : 'N/A'
//                   }</MetaInfo>
//                 </VideoListItem>
//               ))}
//             </VideoList>
//           )}
//         </>
//       ) : (
//         <>
//           <DashboardTitle>Your Channel Insights</DashboardTitle>
//           <PaymentMessage>Unlock your YouTube channel insights</PaymentMessage>
//           <StyledButton onClick={handlePayment} disabled={isProcessing}>
//             {isProcessing ? (
//               <>
//                 Processing
//                 <LoadingSpinner />
//               </>
//             ) : (
//               // 'Proceed to Checkout ($1.99 / week)'
//               'Unlock your Audience Insights ✨'
//             )}
//           </StyledButton>
//         </>
//       )}
//     </Container>
//   );
// };

// export default HomePage;














































































// import React, { useEffect, useState } from 'react';
// import FormComponent from '../components/FormComponent';
// import StepsComponent from '../components/StepsComponent';
// import TrustedByComponent from '../components/TrustedByComponent';
// import HeadlineComponent from '../components/HeadlineComponent';
// import NewspaperCarousel from '../components/NewspaperCarousel';
// import { checkUserStatus } from '../utils/authUtils';
// import { auth, firestore } from '../firebase';
// import { onAuthStateChanged } from 'firebase/auth';
// import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
// import styled from 'styled-components';
// import axios from 'axios';
// import { format, parseISO } from 'date-fns';
// import { marked } from 'marked';
// import ReactPlayer from 'react-player';
// import '../App.css';
// import TryItOutComponent from '../components/TryItOutComponent';
// import { doc, updateDoc } from 'firebase/firestore';
// import HowItWorksComponent from '../components/StepsComponent';



// const Container = styled.div`
//   padding: 40px;
//   text-align: center;
//   // background-color: #f9f9f9;
//   // background-color: #fdfdfd;
//   background-color: #FDFBFD;
//   // background-color: #fffbf4;

//   // background-color: #fff9ef;
//   min-height: 100vh;
//   width: 100%;
// `;

// const DashboardTitle = styled.h2`
//   color: #333;
//   font-size: 2.8rem;
//   margin-top: 30px;
//   margin-bottom: 40px;
//   font-weight: 700;
//   text-transform: uppercase;
//   letter-spacing: 2px;
// `;

// const PaymentMessage = styled.div`
//   color: #333;
//   font-size: 1.8rem;
//   margin-top: 225px;
//   margin-bottom: 30px;
//   font-weight: 500;
// `;

// const Button = styled.button`
//   padding: 15px 30px;
//   // background-color: #007BFF;
//   background-color: #ff0100;
//   color: #fff;
//   border: none;
//   border-radius: 50px;
//   cursor: pointer;
//   font-size: 18px;
//   font-weight: bold;
//   margin-top: 30px;
//   transition: all 0.3s ease;
//   margin-bottom: 200px;

//   &:hover {
//     // background-color: #0056b3;
//     background-color: #dd0100;
//     transform: translateY(-2px);
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   }
// `;

// const VideoList = styled.ul`
//   list-style-type: none;
//   padding: 0;
//   max-width: 900px;
//   margin: 0 auto;
// `;

// const VideoListItem = styled.li`
//   background-color: #fff;
//   border: 1px solid #e0e0e0;
//   border-radius: 15px;
//   padding: 30px;
//   margin: 30px 0;
//   text-align: left;
//   box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
//   transition: all 0.3s ease;

//   &:hover {
//     transform: translateY(-5px);
//     box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
//   }
// `;

// const VideoTitle = styled.h3`
//   font-size: 1.8rem;
//   color: #333;
//   margin-bottom: 15px;
// `;

// const VideoPlayer = styled(ReactPlayer)`
//   margin: 20px 0;
//   border-radius: 10px;
//   overflow: hidden;
// `;

// const SummaryText = styled.div`
//   font-size: 1.1rem;
//   line-height: 1.6;
//   color: #444;
//   margin: 20px 0;
// `;

// const MetaInfo = styled.p`
//   font-size: 1rem;
//   color: #666;
//   margin: 10px 0;
// `;

// const LoadingSpinner = styled.div`
//   border: 3px solid #f3f3f3;
//   border-top: 3px solid #ff0100;
//   border-radius: 50%;
//   width: 20px;
//   height: 20px;
//   animation: spin 1s linear infinite;
//   margin-left: 10px;
//   display: inline-block;

//   @keyframes spin {
//     0% { transform: rotate(0deg); }
//     100% { transform: rotate(360deg); }
//   }
// `;

// const HomePage = () => {
//   const [isLoading, setIsLoading] = useState(true);
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [isMember, setIsMember] = useState(false);
//   const [summaries, setSummaries] = useState([]);
//   const [isProcessing, setIsProcessing] = useState(false);

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, async (user) => {
//       if (user) {
//         const userDoc = await checkUserStatus();
//         setIsLoggedIn(true);
//         setIsMember(userDoc.isMember);
//         if (userDoc.isMember) {
//           const q = query(
//             collection(firestore, 'summaries'), 
//             where('userId', '==', user.uid),           
//             orderBy('publishedTime', 'desc')
//           );

//           onSnapshot(q, (querySnapshot) => {
//             const summariesList = querySnapshot.docs.map(doc => {
//               const data = doc.data();
//               return {
//                 id: doc.id,
//                 ...data,
//                 summary: marked(data.summary),
//                 timestamp: data.timestamp ? format(data.timestamp.toDate(), 'PPpp') : 'N/A',
//                 publishedTime: data.publishedTime && data.publishedTime !== '' ? data.publishedTime : null
//               };
//             });
//             setSummaries(summariesList);
//           });
//         }
//       } else {
//         setIsLoggedIn(false);
//         setIsMember(false);
//       }
//       setIsLoading(false);
//     });

//     return () => unsubscribe();
//   }, []);



//   const handlePayment = async () => {
//     try {
//       setIsProcessing(true);
//       const user = auth.currentUser;
  
//       // Call the new function to activate the user without Stripe
//       const response = await axios.post('https://us-central1-youtubecommentssummary.cloudfunctions.net/activateUserWithoutStripe', {
//         userId: user.uid,
//       });
  
//       console.log('User activated without Stripe payment');
  
//       // If the response is successful, directly update Firestore here
//       if (response.status === 200) {
//         await updateDoc(doc(firestore, 'users', user.uid), {
//           isMember: true,
//         });
  
//         console.log('User membership status updated to isMember: true');
  
//         // Use React Router's navigate function to redirect to the home page
//         window.location.href = '/home';  // Make sure this path exists and is correct
//       } else {
//         throw new Error('Error activating user');
//       }
//     } catch (error) {
//       console.error('Error activating user or updating membership status: ', error);
//       setIsProcessing(false); // Reset processing state if there's an error
//     }
//   };
  


//   //// For Stripe BELOW
//   // const handlePayment = async () => {
//   //   try {
//   //     setIsProcessing(true);
//   //     const user = auth.currentUser;
//   //     const response = await axios.post('https://us-central1-youtubecommentssummary.cloudfunctions.net/createCheckoutSession', {
//   //       email: user.email,
//   //       userId: user.uid
//   //     });

//   //     window.location.href = response.data.url;
//   //   } catch (error) {
//   //     console.error('Error initiating Stripe checkout: ', error);
//   //     setIsProcessing(false);
//   //   }
//   // };

//   if (isLoading) {
//     return <Container><LoadingSpinner /></Container>;
//   }


//           {/* <TrustedByComponent /> */}
//         {/* <NewspaperCarousel /> */}
//                 {/* <FormComponent setIsProcessing={setIsProcessing} /> */}


//   if (!isLoggedIn) {
//     return (
//       <Container>
//         <div style={{ margin: '30px 0px 350px 0' }}>
//           <HeadlineComponent />
//           <NewspaperCarousel />
//         </div>
//         <div style={{ margin: '0px 0px 300px 0' }}>
//           <StepsComponent />
//         </div>
//         <div style={{ margin: '0px 0px 300px 0' }}>
//         <TrustedByComponent />
//         </div>

//         <TryItOutComponent />
//       </Container>
//     );
//   }

//   if (isMember) {
//     return (
//       <Container>
//         <DashboardTitle>Your Channel Insights</DashboardTitle>
//         {summaries.length === 0 ? (
//           <PaymentMessage>
//             Processing latest video...
//             <LoadingSpinner />
//           </PaymentMessage>
//         ) : (
//           <VideoList>
//             {summaries.map(summary => (
//               <VideoListItem key={summary.id}>
//                 <VideoTitle dangerouslySetInnerHTML={{ __html: summary.videoTitle }}/>
//                 <VideoPlayer 
//                   url={`https://www.youtube.com/watch?v=${summary.videoId}`} 
//                   controls
//                   width="100%"
//                   height="400px"
//                 />
//                 <SummaryText dangerouslySetInnerHTML={{ __html: summary.summary }} />
//                 <MetaInfo>Channel: {summary.youtubeChannel}</MetaInfo>
//                 <MetaInfo>Published on: {
//                   summary.publishedTime ? 
//                     (() => {
//                       try {
//                         return format(parseISO(summary.publishedTime), 'MMMM do, yyyy');
//                       } catch (error) {
//                         console.error('Error formatting date:', error, summary.publishedTime);
//                         return 'Invalid Date';
//                       }
//                     })() 
//                     : 'N/A'
//                 }</MetaInfo>
//               </VideoListItem>
//             ))}
//           </VideoList>
//         )}
//       </Container>
//     );
//   }

//   return (
//     <Container>
//       {isProcessing ? (
//         <PaymentMessage>
//           Processing...
//           <LoadingSpinner />
//         </PaymentMessage>
//       ) : (
//         <>
//           <DashboardTitle>Your Channel Insights</DashboardTitle>
//           <PaymentMessage>Unlock your YouTube channel insights</PaymentMessage>
//           <Button onClick={handlePayment} disabled={isProcessing}>
//             {isProcessing ? (
//               <>
//                 Processing
//                 <LoadingSpinner />
//               </>
//             ) : (
//               // 'Proceed to Checkout ($1.99 / week)'
//               'Unlock your Audience Insights ✨'
//             )}
//           </Button>
//           {/* <StepsComponent />
//           <TrustedByComponent />
//           <NewspaperCarousel /> */}
//         </>
//       )}
//     </Container>
//   );
// };

// export default HomePage;
