import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { UserPlus, Coffee, Sparkles } from 'lucide-react';
import { useInView } from 'react-intersection-observer';

// Keyframes for the vertical fade-in animation
const fadeInUp = keyframes`
  from { 
    opacity: 0; 
    transform: translateY(30px); 
  }
  to { 
    opacity: 1; 
    transform: translateY(0); 
  }
`;

// CSS for the animation
const fadeInUpAnimation = css`
  animation: ${fadeInUp} 0.8s ease-out forwards; /* 'forwards' to maintain the end state */
`;

// Styled Components
const Container = styled.section`
  max-width: 1200px;
  margin: 100px auto;
  padding: 0 20px;

  @media (max-width: 768px) { /* Tablet */
    margin: 50px auto;
    padding: 0 15px;
  }

  @media (max-width: 480px) { /* Mobile */
    margin: 30px auto;
    padding: 0 10px;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 60px;
  opacity: 0; /* Initial opacity to ensure animation works */
  transform: translateY(30px); /* Initial transform to start the animation */
  ${props => props.isVisible && fadeInUpAnimation}; /* Apply animation when visible */

  @media (max-width: 768px) { /* Tablet */
    font-size: 2rem;
    margin-bottom: 40px;
  }

  @media (max-width: 480px) { /* Mobile */
    font-size: 1.8rem;
    margin-bottom: 30px;
  }
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: 768px) { /* Tablet */
    flex-direction: column;
    gap: 30px;
  }

  @media (max-width: 480px) { /* Mobile */
    gap: 20px;
  }
`;

const StepWrapper = styled.div`
  flex: 1;
  text-align: center;
  opacity: 0; /* Initial opacity */
  transform: translateY(30px); /* Initial transform */
  ${props => props.isVisible && fadeInUpAnimation}; /* Apply animation when visible */
  animation-delay: ${props => props.delay}; /* Delay for each step */

  @media (max-width: 480px) { /* Mobile */
    margin-bottom: 20px;
  }
`;

const IconWrapper = styled.div`
  background-color: #FF3B30;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;

  @media (max-width: 480px) { /* Mobile */
    width: 60px;
    height: 60px;
  }
`;

const StepTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 15px;

  @media (max-width: 480px) { /* Mobile */
    font-size: 1.3rem;
    margin-bottom: 10px;
  }
`;

const StepDescription = styled.p`
  font-size: 1.1rem;
  color: #656465;
  line-height: 1.5;

  @media (max-width: 768px) { /* Tablet */
    font-size: 1rem;
  }

  @media (max-width: 480px) { /* Mobile */
    font-size: 0.95rem;
  }
`;

// Step Component with Intersection Observer
const StepComponent = ({ icon, title, description, delay }) => {
  const { ref, inView } = useInView({ triggerOnce: true }); // Observe each step

  return (
    <StepWrapper ref={ref} isVisible={inView} delay={delay}>
      <IconWrapper>{icon}</IconWrapper>
      <StepTitle>{title}</StepTitle>
      <StepDescription>{description}</StepDescription>
    </StepWrapper>
  );
};

const HowItWorksComponent = () => {
  // Intersection Observer for Title
  const { ref: titleRef, inView: titleVisible } = useInView({ triggerOnce: true });

  const steps = [
    {
      delay: '0.2s',
      icon: <UserPlus size={40} color="#fff" />,
      title: 'Sign up once',
      description: 'Just enter your email and YouTube handle. That\'s it! No complicated setup, we promise.',
    },
    {
      delay: '0.2s',
      icon: <Coffee size={40} color="#fff" />,
      title: 'Relax and create',
      description: 'Go back to doing what you love - making great content. We\'ll quietly work our magic in the background.',
    },
    {
      delay: '0.2s',
      icon: <Sparkles size={40} color="#fff" />,
      title: 'Get insights, save hours',
      description: 'Wake up to smart, actionable insights in your inbox. No more hours lost in analytics - we\'ve done the hard work for you!',
    },
  ];

  return (
    <Container>
      <Title ref={titleRef} isVisible={titleVisible}>
        How we unlock your viewer insights
      </Title>
      <StepsContainer>
        {steps.map((step, index) => (
          <StepComponent
            key={index}
            icon={step.icon}
            title={step.title}
            description={step.description}
            delay={step.delay} // Delay animation for each step
          />
        ))}
      </StepsContainer>
    </Container>
  );
};

export default HowItWorksComponent;





// import React from 'react';
// import styled, { keyframes, css } from 'styled-components';
// import { UserPlus, Coffee, Sparkles } from 'lucide-react';
// import { useInView } from 'react-intersection-observer';

// // Keyframes for the vertical fade-in animation
// const fadeInUp = keyframes`
//   from { 
//     opacity: 0; 
//     transform: translateY(30px); 
//   }
//   to { 
//     opacity: 1; 
//     transform: translateY(0); 
//   }
// `;

// // CSS for the animation
// const fadeInUpAnimation = css`
//   animation: ${fadeInUp} 0.8s ease-out forwards; /* 'forwards' to maintain the end state */
// `;

// // Styled Components
// const Container = styled.section`
//   max-width: 1200px;
//   margin: 100px auto;
//   padding: 0 20px;
// `;

// const Title = styled.h2`
//   font-size: 2.5rem;
//   font-weight: 800;
//   text-align: center;
//   margin-bottom: 60px;
//   opacity: 0; /* Initial opacity to ensure animation works */
//   transform: translateY(30px); /* Initial transform to start the animation */
//   ${props => props.isVisible && fadeInUpAnimation}; /* Apply animation when visible */
// `;

// const StepsContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   gap: 40px;

//   @media (max-width: 768px) {
//     flex-direction: column;
//   }
// `;

// const StepWrapper = styled.div`
//   flex: 1;
//   text-align: center;
//   opacity: 0; /* Initial opacity */
//   transform: translateY(30px); /* Initial transform */
//   ${props => props.isVisible && fadeInUpAnimation}; /* Apply animation when visible */
//   animation-delay: ${props => props.delay}; /* Delay for each step */
// `;

// const IconWrapper = styled.div`
//   // background-color: #f0f0f0;
//   background-color: #FF3B30;
//   width: 80px;
//   height: 80px;
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin: 0 auto 20px;
// `;

// const StepTitle = styled.h3`
//   font-size: 1.5rem;
//   font-weight: 700;
//   margin-bottom: 15px;
// `;

// const StepDescription = styled.p`
//   font-size: 1.1rem;
//   color: #656465;
//   line-height: 1.5;
// `;

// // Step Component with Intersection Observer
// const StepComponent = ({ icon, title, description, delay }) => {
//   const { ref, inView } = useInView({ triggerOnce: true }); // Observe each step

//   return (
//     <StepWrapper ref={ref} isVisible={inView} delay={delay}>
//       <IconWrapper>{icon}</IconWrapper>
//       <StepTitle>{title}</StepTitle>
//       <StepDescription>{description}</StepDescription>
//     </StepWrapper>
//   );
// };

// const HowItWorksComponent = () => {
//   // Intersection Observer for Title
//   const { ref: titleRef, inView: titleVisible } = useInView({ triggerOnce: true });

//   const steps = [
//     {
//       delay: '0.2s',
//       icon: <UserPlus size={40} color="#fff" />,
//       title: 'Sign up once',
//       description: 'Just enter your email and YouTube handle. That\'s it! No complicated setup, we promise.',
//     },
//     {
//       delay: '0.2s',
//       icon: <Coffee size={40} color="#fff" />,
//       title: 'Relax and create',
//       description: 'Go back to doing what you love - making great content. We\'ll quietly work our magic in the background.',
//     },
//     {
//       delay: '0.2s',
//       icon: <Sparkles size={40} color="#fff" />,
//       title: 'Get insights, save hours',
//       description: 'Wake up to smart, actionable insights in your inbox. No more hours lost in analytics - we\'ve done the hard work for you!',
//     },
//   ];

//   return (
//     <Container>
//       <Title ref={titleRef} isVisible={titleVisible}>
//         How we unlock your viewer insights
//       </Title>
//       <StepsContainer>
//         {steps.map((step, index) => (
//           <StepComponent
//             key={index}
//             icon={step.icon}
//             title={step.title}
//             description={step.description}
//             delay={step.delay} // Delay animation for each step
//           />
//         ))}
//       </StepsContainer>
//     </Container>
//   );
// };

// export default HowItWorksComponent;






















































// import React from 'react';
// import styled, { keyframes, css } from 'styled-components';
// import { UserPlus, Coffee, Sparkles } from 'lucide-react';
// import { useInView } from 'react-intersection-observer';

// // Keyframes for the animation
// const fadeIn = keyframes`
//   from { opacity: 0; transform: translateY(20px); }
//   to { opacity: 1; transform: translateY(0); }
// `;

// // Animation styles
// const fadeInAnimation = css`
//   animation: ${fadeIn} 0.8s ease-out;
// `;

// // Styled Components
// const Container = styled.section`
//   max-width: 1200px;
//   margin: 100px auto;
//   padding: 0 20px;
// `;

// const Title = styled.h2`
//   font-size: 2.5rem;
//   font-weight: 800;
//   text-align: center;
//   margin-bottom: 60px;
//   ${props => props.isVisible && fadeInAnimation};
// `;

// const StepsContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   gap: 40px;

//   @media (max-width: 768px) {
//     flex-direction: column;
//   }
// `;

// const StepWrapper = styled.div`
//   flex: 1;
//   text-align: center;
//   ${props => props.isVisible && fadeInAnimation};
//   animation-delay: ${props => props.delay};
// `;

// const IconWrapper = styled.div`
//   background-color: #f0f0f0;
//   width: 80px;
//   height: 80px;
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin: 0 auto 20px;
// `;

// const StepTitle = styled.h3`
//   font-size: 1.5rem;
//   font-weight: 700;
//   margin-bottom: 15px;
// `;

// const StepDescription = styled.p`
//   font-size: 1rem;
//   color: #656465;
//   line-height: 1.5;
// `;

// // Step Component with Intersection Observer
// const StepComponent = ({ icon, title, description, delay }) => {
//   const { ref, inView } = useInView({ triggerOnce: true });

//   return (
//     <StepWrapper ref={ref} isVisible={inView} delay={delay}>
//       <IconWrapper>{icon}</IconWrapper>
//       <StepTitle>{title}</StepTitle>
//       <StepDescription>{description}</StepDescription>
//     </StepWrapper>
//   );
// };

// const HowItWorksComponent = () => {
//   // Intersection Observer for Title
//   const { ref: titleRef, inView: titleVisible } = useInView({ triggerOnce: true });

//   const steps = [
//     {
//       delay: '0.2s',
//       icon: <UserPlus size={40} color="#FF0000" />,
//       title: 'Sign up once',
//       description: 'Just enter your email and YouTube handle. That\'s it! No complicated setup, we promise.',
//     },
//     {
//       delay: '0.4s',
//       icon: <Coffee size={40} color="#FF0000" />,
//       title: 'Relax and create',
//       description: 'Go back to doing what you love - making great content. We\'ll quietly work our magic in the background.',
//     },
//     {
//       delay: '0.6s',
//       icon: <Sparkles size={40} color="#FF0000" />,
//       title: 'Get insights, save hours',
//       description: 'Wake up to smart, actionable insights in your inbox. No more hours lost in analytics - we\'ve done the hard work for you!',
//     },
//   ];

//   return (
//     <Container>
//       <Title ref={titleRef} isVisible={titleVisible}>
//         How we save you time
//       </Title>
//       <StepsContainer>
//         {steps.map((step, index) => (
//           <StepComponent
//             key={index}
//             icon={step.icon}
//             title={step.title}
//             description={step.description}
//             delay={step.delay}
//           />
//         ))}
//       </StepsContainer>
//     </Container>
//   );
// };

// export default HowItWorksComponent;



// import React from 'react';
// import styled, { keyframes } from 'styled-components';
// import { UserPlus, Coffee, Sparkles } from 'lucide-react';

// const fadeIn = keyframes`
//   from { opacity: 0; transform: translateY(20px); }
//   to { opacity: 1; transform: translateY(0); }
// `;

// const Container = styled.section`
//   max-width: 1200px;
//   margin: 100px auto;
//   padding: 0 20px;
// `;

// const Title = styled.h2`
//   font-size: 2.5rem;
//   font-weight: 800;
//   text-align: center;
//   margin-bottom: 60px;
//   animation: ${fadeIn} 0.8s ease-out;
// `;

// const StepsContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   gap: 40px;

//   @media (max-width: 768px) {
//     flex-direction: column;
//   }
// `;

// const Step = styled.div`
//   flex: 1;
//   text-align: center;
//   animation: ${fadeIn} 0.8s ease-out;
//   animation-delay: ${props => props.delay};
// `;

// const IconWrapper = styled.div`
//   background-color: #f0f0f0;
//   width: 80px;
//   height: 80px;
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin: 0 auto 20px;
// `;

// const StepTitle = styled.h3`
//   font-size: 1.5rem;
//   font-weight: 700;
//   margin-bottom: 15px;
// `;

// const StepDescription = styled.p`
//   font-size: 1rem;
//   color: #656465;
//   line-height: 1.5;
// `;

// const HowItWorksComponent = () => {
//   return (
//     <Container>
//       <Title>How we save you time</Title>
//       <StepsContainer>
//         <Step delay="0.2s">
//           <IconWrapper>
//             <UserPlus size={40} color="#FF0000" />
//           </IconWrapper>
//           <StepTitle>Sign up once</StepTitle>
//           <StepDescription>
//             Just enter your email and YouTube handle. That's it! No complicated setup, we promise.
//           </StepDescription>
//         </Step>
//         <Step delay="0.4s">
//           <IconWrapper>
//             <Coffee size={40} color="#FF0000" />
//           </IconWrapper>
//           <StepTitle>Relax and create</StepTitle>
//           <StepDescription>
//             Go back to doing what you love - making great content. We'll quietly work our magic in the background.
//           </StepDescription>
//         </Step>
//         <Step delay="0.6s">
//           <IconWrapper>
//             <Sparkles size={40} color="#FF0000" />
//           </IconWrapper>
//           <StepTitle>Get insights, save hours</StepTitle>
//           <StepDescription>
//             Wake up to smart, actionable insights in your inbox. No more hours lost in analytics - we've done the hard work for you!
//           </StepDescription>
//         </Step>
//       </StepsContainer>
//     </Container>
//   );
// };

// export default HowItWorksComponent;



// // src/components/StepsComponent.js
// import React from 'react';
// import styled from 'styled-components';
// import { CheckCircle } from 'lucide-react'; // Importing the CheckCircle icon

// const Container = styled.div`
//   background-color: #e2e2e2;
//   padding: 20px 10px;
//   border-radius: 10px;
//   max-width: 800px;
//   margin: 40px auto 0 auto; /* Added margin-top to create space above */
// `;

// const Title = styled.h3`
//   color: #111827; /* Dark text color */
//   font-size: 20px;
//   font-weight: bold;
//   margin-bottom: 20px;
//   text-align: left; /* Left-align the title */
// `;

// const List = styled.ul`
//   list-style: none;
//   padding: 0;
//   margin: 0;
// `;

// const ListItem = styled.li`
//   display: flex;
//   align-items: flex-start; /* Align items to the top */
//   margin-bottom: 15px;
//   color: #111827; /* Dark text color */
//   font-size: 18px;
//   line-height: 1.4; /* Add line height for better spacing */

//   & > svg {
//     margin-right: 10px;
//     margin-top: 0px; /* Ensure icon aligns with top of text */
//   }
// `;

// const ListItemText = styled.div`
//   display: inline-block; /* Ensure text is always left-aligned */
//   text-align: left; /* Ensure text is always left-aligned */
// `;

// const StepsComponent = () => (
//   <Container>
//     <Title>How You'll Save Time</Title>
//     <List>
//       <ListItem>
//         <CheckCircle size={24} strokeWidth={2} color="#22C55E" />
//         {/* <ListItemText>Get instant audience insights, eliminating hours of manual analysis</ListItemText> */}
//         <ListItemText>Understand your audience quickly with AI-powered insights</ListItemText>
//       </ListItem>
//       <ListItem>
//         <CheckCircle size={24} strokeWidth={2} color="#22C55E" />
//         {/* <ListItemText>Quickly create content that resonates with your viewers</ListItemText> */}
//         <ListItemText>Streamline your content planning process</ListItemText>
//       </ListItem>
//       <ListItem>
//         <CheckCircle size={24} strokeWidth={2} color="#22C55E" />
//         {/* <ListItemText>Efficiently increase views, engagement, and subscribers</ListItemText> */}
//         <ListItemText>Reduce time spent on manual analytics and reporting</ListItemText>
//       </ListItem>
//     </List>
//   </Container>
// );

// export default StepsComponent;

