import React, { useState } from 'react';
import styled from 'styled-components';
import { getFunctions, httpsCallable } from 'firebase/functions';
import '../App.css';

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  background-color: #f9f9f9;
  min-height: 100vh;
  color: #333;
`;

const ContactTitle = styled.h1`
  font-size: 48px;
  margin-bottom: 20px;
  text-align: center;
  color: #1a1a1a;
`;

const ContactSubtitle = styled.p`
  font-size: 24px;
  margin-bottom: 40px;
  text-align: center;
  color: #6c6c6c;
`;

const ContactFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.label`
  color: #333;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  border: 2px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  background-color: #fff;
  color: #333;
  outline: none;

  &:focus {
    border-color: #FF0100;
  }
`;

const TextArea = styled.textarea`
  padding: 10px;
  margin-bottom: 20px;
  border: 2px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  background-color: #fff;
  color: #333;
  outline: none;
  resize: vertical;

  &:focus {
    border-color: #FF0100;
  }
`;

const Button = styled.button`
  padding: 15px 20px;
  background-color: ${props => props.disabled ? '#cccccc' : '#FF0100'};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.disabled ? '#cccccc' : '#DD0100'};
  }
`;

const ResponseMessage = styled.p`
  margin-top: 20px;
  color: ${props => props.isError ? '#FF0000' : '#008000'};
  font-weight: bold;
`;

const ContactPage = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResponseMessage('');
    setIsError(false);

    const functions = getFunctions();
    const sendContactEmail = httpsCallable(functions, 'sendContactEmail');

    try {
      const result = await sendContactEmail(formData);
      if (result.data.success) {
        setResponseMessage('Your message has been sent successfully.');
        setFormData({ name: '', email: '', message: '' }); // Clear form after successful submission
      } else {
        setResponseMessage('Failed to send your message. Please try again later.');
        setIsError(true);
      }
    } catch (error) {
      console.error('Error sending contact form:', error);
      setResponseMessage('An error occurred. Please try again later.');
      setIsError(true);
    }

    setLoading(false);
  };

  return (
    <ContactContainer>
      <ContactTitle>Contact us</ContactTitle>
      <ContactSubtitle>
        If you have any questions or comments, please don't hesitate to reach out using the contact form.
      </ContactSubtitle>
      <ContactFormContainer>
        <Form onSubmit={handleSubmit}>
          <Label htmlFor="name">Name</Label>
          <Input type="text" id="name" name="name" required placeholder="First Name Last Name" value={formData.name} onChange={handleChange} />
          <Label htmlFor="email">Email</Label>
          <Input type="email" id="email" name="email" required placeholder="Email" value={formData.email} onChange={handleChange} />
          <Label htmlFor="message">Message</Label>
          <TextArea id="message" name="message" required placeholder="Message" rows="4" value={formData.message} onChange={handleChange} />
          <Button type="submit" disabled={loading}>{loading ? 'Sending...' : 'Submit'}</Button>
          {responseMessage && <ResponseMessage isError={isError}>{responseMessage}</ResponseMessage>}
        </Form>
      </ContactFormContainer>
    </ContactContainer>
  );
};

export default ContactPage;