import React, { useState } from 'react';
import styled from 'styled-components';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import axios from 'axios';

const FormWrapper = styled.div`
  background-color: #fff;
  border-radius: 20px;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: -10px;
  right: -10px;
  background: #fff;
  border: none;
  font-size: 24px;
  font-weight: bold;
  color: #888;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  &:hover {
    color: #333;
  }
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const Logo = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
`;

const Title = styled.h2`
  font-size: 28px;
  font-weight: bold;
  margin: 10px 0;
`;

const Subtitle = styled.p`
  font-size: 16px;
  color: #666;
  margin: 0 0 20px;

  span {
    color: #FF0000; // YouTube red
  }

`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 12px;
  margin-bottom: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  &:focus {
    outline: none;
    border-color: #ff0000;
  }
`;

const Button = styled.button`
  padding: 12px;
  background-color: #ff0000;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px; // Ensure consistent height
  &:hover {
    background-color: #cc0000;
  }
`;

// const ErrorMessage = styled.p`
//   color: red;
//   font-size: 14px;
//   margin-top: -8px;
//   margin-bottom: 8px;
// `;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: -8px;
  margin-bottom: 8px;
  text-align: left;
  width: 100%;
`;


const Spinner = styled.div`
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 3px solid #fff;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-left: 10px;
  flex-shrink: 0; // Prevent the spinner from shrinking
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

// const Spinner = styled.div`
//   border: 3px solid rgba(255, 255, 255, 0.3);
//   border-radius: 50%;
//   border-top: 3px solid #fff;
//   width: 20px;
//   height: 20px;
//   animation: spin 1s linear infinite;
//   margin-left: 10px;
//   @keyframes spin {
//     0% { transform: rotate(0deg); }
//     100% { transform: rotate(360deg); }
//   }
// `;

const FormComponent = ({ setIsProcessing, onClose }) => {
  const [formData, setFormData] = useState({
    youtubeChannel: '',
    email: '',
    password: '',
  });

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [youtubeChannelError, setYoutubeChannelError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name === 'email') setEmailError('');
    if (e.target.name === 'password') setPasswordError('');
    if (e.target.name === 'youtubeChannel') setYoutubeChannelError('');
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const validateYoutubeChannel = async (channelHandle) => {
    const youtubeApiKey = 'AIzaSyDrvc1bhX95Y4ViJc12c_bd0mkmI3DJEfQ';
    
    const url = `https://www.googleapis.com/youtube/v3/channels?part=id&forHandle=${channelHandle}&key=${youtubeApiKey}`;  
    try {
      const response = await axios.get(url);
      console.log("YouTube API Response: ", response.data);
      
      return response.data.items && response.data.items.length > 0;
    } catch (error) {
      console.error("Error checking YouTube channel: ", error);
      return false;
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (loading) return;
  //   let isValid = true;
  
  //   if (!validateEmail(formData.email)) {
  //     setEmailError('Please enter a valid email address');
  //     isValid = false;
  //   }
  
  //   if (!validatePassword(formData.password)) {
  //     setPasswordError('Password must be at least 6 characters long');
  //     isValid = false;
  //   }
  
  //   const channelName = formData.youtubeChannel.startsWith('@') ? formData.youtubeChannel.substring(1) : formData.youtubeChannel;
  //   const isYoutubeChannelValid = await validateYoutubeChannel(channelName);
  
  //   if (!isYoutubeChannelValid) {
  //     setYoutubeChannelError('YouTube channel does not exist');
  //     isValid = false;
  //   }
  
  //   if (!isValid) {
  //     return;
  //   }
  
  //   console.log('Form submitted');
  //   console.log('Form data: ', formData);
  
  //   try {
  //     setLoading(true);
  //     setIsProcessing(true);
  //     const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
  //     console.log('User created: ', userCredential);
  
  //     // Add user data to Firestore
  //     await axios.post('https://us-central1-youtubecommentssummary.cloudfunctions.net/createUser', {
  //       userId: userCredential.user.uid,
  //       email: formData.email,
  //       youtubeChannel: formData.youtubeChannel,
  //     });
  
  //     // Call the new function to activate the user without Stripe
  //     const response = await axios.post('https://us-central1-youtubecommentssummary.cloudfunctions.net/activateUserWithoutStripe', {
  //       userId: userCredential.user.uid,
  //     });
  
  //     console.log('User activated without Stripe payment');
  
  //     // Redirect to the membership status update page
  //     if (response.status === 200) {
  //       window.location.href = `https://use-luminate.com/update-membership-status?session_id=${userCredential.user.uid}`;
  //     } else {
  //       throw new Error('Error activating user');
  //     }
  //   } catch (error) {
  //     console.error('Error signing up: ', error);
  //     setLoading(false);
  //     setIsProcessing(false);
  
  //     // Handle specific Firebase errors
  //     if (error.code === 'auth/email-already-in-use') {
  //       setEmailError('This email is already in use. Please use a different email.');
  //     } else {
  //       // General error message
  //       setEmailError('An error occurred during sign up. Please try again later.');
  //     }
  //   }
  // };




  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;
  
    if (!validateEmail(formData.email)) {
      setEmailError('Please enter a valid email address');
      isValid = false;
    }
  
    if (!validatePassword(formData.password)) {
      setPasswordError('Password must be at least 6 characters long');
      isValid = false;
    }
  
    const channelName = formData.youtubeChannel.startsWith('@') ? formData.youtubeChannel.substring(1) : formData.youtubeChannel;
    const isYoutubeChannelValid = await validateYoutubeChannel(channelName);
  
    if (!isYoutubeChannelValid) {
      setYoutubeChannelError('YouTube channel does not exist');
      isValid = false;
    }
  
    if (!isValid) {
      return;
    }
  
    console.log('Form submitted');
    console.log('Form data: ', formData);
  

    // try {
    //   setLoading(true);
    //   setIsProcessing(true); // Set processing state to true
    //   const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
    //   console.log('User created: ', userCredential);
    
    //   // Add user data to Firestore
    //   await axios.post('https://us-central1-youtubecommentssummary.cloudfunctions.net/createUser', {
    //     userId: userCredential.user.uid,
    //     firstName: formData.firstName,
    //     lastName: formData.lastName,
    //     email: formData.email,
    //     youtubeChannel: formData.youtubeChannel
    //   });
    
    //   // Call the new function to activate the user without Stripe
    //   const response = await axios.post('https://us-central1-youtubecommentssummary.cloudfunctions.net/activateUserWithoutStripe', {
    //     userId: userCredential.user.uid
    //   });
    
    //   console.log('User activated without Stripe payment');
    
    //   // Redirect to the membership status update page
    //   if (response.status === 200) {
    //     window.location.href = `https://use-luminate.com/update-membership-status?session_id=${userCredential.user.uid}`;
    //   } else {
    //     throw new Error('Error activating user');
    //   }
    // } catch (error) {
    //   console.error('Error signing up: ', error);
    //   setLoading(false);
    //   setIsProcessing(false); // Reset processing state if there's an error
    
    //   // Handle specific Firebase errors
    //   if (error.code === 'auth/email-already-in-use') {
    //     setEmailError('This email is already in use. Please use a different email.');
    //   } else {
    //     // General error message
    //     setEmailError('An error occurred during sign up. Please try again later.');
    //   }
    // }
    
    ////// Old Method with Stripe BELOW

    try {
      setLoading(true);
      setIsProcessing(true); // Set processing state to true
      const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
      console.log('User created: ', userCredential);
  
      // Add user data to Firestore
      await axios.post('https://us-central1-youtubecommentssummary.cloudfunctions.net/createUser', {
        userId: userCredential.user.uid,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        youtubeChannel: formData.youtubeChannel
      });
  
      // Create Stripe checkout session
      const response = await axios.post('https://us-central1-youtubecommentssummary.cloudfunctions.net/createCheckoutSession', {
        email: formData.email,
        userId: userCredential.user.uid
      });
  
      console.log('User data sent to cloud function');
  
      if (response.data && response.data.url) {
        window.location.href = response.data.url;
      } else {
        throw new Error('Stripe checkout session URL not received');
      }
    } catch (error) {
      console.error('Error signing up: ', error);
      setLoading(false);
      setIsProcessing(false); // Reset processing state if there's an error

      // Handle specific Firebase errors
      if (error.code === 'auth/email-already-in-use') {
        setEmailError('This email is already in use. Please use a different email.');
      } else {
        // General error message
        setEmailError('An error occurred during sign up. Please try again later.');
      }
    }
  };







  return (
    <FormWrapper>
      <CloseButton onClick={onClose}>×</CloseButton>
      <Header>
        <Logo>Luminate</Logo>
        <Title>Sign up with Luminate!</Title>
        <Subtitle>Grow your YouTube channel with AI-powered insights</Subtitle>
      </Header>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="youtubeChannel"
          placeholder="@YoutubeHandle"
          value={formData.youtubeChannel}
          onChange={handleChange}
          required
        />
        {youtubeChannelError && <ErrorMessage>{youtubeChannelError}</ErrorMessage>}
        <Input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
        <Input
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleChange}
          required
        />
        {passwordError && <ErrorMessage>{passwordError}</ErrorMessage>}
        <Button type="submit" disabled={loading}>
          <span>{loading ? 'Processing' : 'Continue to Checkout'}</span>
          {loading && <Spinner />}
        </Button>
      </Form>
    </FormWrapper>
  );
};

export default FormComponent;






// import React, { useState } from 'react';
// import styled from 'styled-components';
// import { createUserWithEmailAndPassword } from 'firebase/auth';
// import { auth } from '../firebase';
// import axios from 'axios';
// import { Users, ArrowRight } from 'lucide-react'; // Importing the Users icon

// const FormContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   // background-color: #f9f9f9;
//   // background-color: #ffffff;
//   padding-top: 20px;
//   border-radius: 10px;
//   width: 100%;
//   max-width: 800px; /* Adjusted to match the width of the boxes above */
//   margin: 0 auto;
//   position: relative; /* For positioning the close button */
// `;

// // const CloseButton = styled.button`
// //   position: absolute;
// //   top: 5px;
// //   right: 15px;
// //   background: none;
// //   border: none;
// //   font-size: 1.5rem;
// //   cursor: pointer;
// //   color: #333;
// //   &:hover {
// //     color: #ff0100;
// //   }
// // `;


// const CloseButton = styled.button`
//   position: absolute;
//   top: -35px; // Move it above the container
//   right: -5px; // Move it outside the container
//   background: none;
//   border: none;
//   font-size: 30px; // Increased size for better visibility
//   font-weight: regular;
//   border-radius: 50%; // Make it circular

//   cursor: pointer;
//   color: #ccc; // A lighter color to match the image
//   padding: 3px; // Increased clickable area
//   &:hover {
//     color: #aaa;
//   }
// `;


// const Form = styled.form`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
// `;

// const Label = styled.label`
//   color: #333;
//   margin-bottom: 5px;
//   text-align: left;
//   font-weight: bold;
// `;

// const Input = styled.input`
//   padding: 15px;
//   margin-bottom: 10px;
//   border: 1.5px solid #ddd;
//   border-radius: 5px;
//   font-size: 16px;
//   background-color: #fff;
//   color: #333;
//   outline: none;
//   width: 100%;

//   &:focus {
//     border-color: #ff0100;
//   }
// `;

// const Button = styled.button`
//   padding: 15px 20px;
//   background-color: #ee0100;
//   // background-color: #fff;
//   color: #fff;
//   // color: #ee0100;
//   border: none;
//   border-radius: 50px;
//   cursor: pointer;
//   font-size: 18px;
//   font-weight: bold;
//   margin-top: 20px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;

//   &:hover {
//     background-color: #dd0100;
//     // color: #fff;
//   }
// `;

// const NameContainer = styled.div`
//   display: flex;
//   gap: 10px;
//   flex-wrap: wrap;
// `;

// const HalfInput = styled(Input)`
//   flex: 1 1 calc(50% - 5px);
//   min-width: 120px;
// `;

// const ErrorMessage = styled.p`
//   color: red;
//   font-size: 14px;
//   margin-bottom: 10px;
//   margin-left: 5px;
//   text-align: left;
// `;

// const Spinner = styled.div`
//   border: 4px solid rgba(255, 255, 255, 0.3);
//   border-left-color: #fff;
//   border-radius: 50%;
//   width: 20px;
//   height: 20px;
//   animation: spin 1s linear infinite;
//   margin-left: 10px;

//   @keyframes spin {
//     0% {
//       transform: rotate(0deg);
//     }
//     100% {
//       transform: rotate(360deg);
//     }
//   }
// `;

// const InfoContainer = styled.div`
//   display: flex;
//   align-items: flex-start; /* Align items to the top */
//   font-size: 14px;
//   color: #222222;
//   margin-top: 5px;
//   width: 100%;
//   text-align: left;

//   & > svg {
//     margin-right: 5px;
//     flex-shrink: 0; /* Prevent the icon from shrinking */
//   }

//   span {
//     display: inline;
//     flex: 1;
//   }
// `;

// const PricingInfo = styled.p`
//   font-size: 14px;
//   color: #222222;
//   margin-top: 5px;
//   width: 100%;
//   text-align: left;
// `;

// const FormComponent = ({ setIsProcessing, onClose }) => {
//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     email: '',
//     youtubeChannel: '',
//     password: '',
//   });

//   const [emailError, setEmailError] = useState('');
//   const [passwordError, setPasswordError] = useState('');
//   const [youtubeChannelError, setYoutubeChannelError] = useState('');
//   const [loading, setLoading] = useState(false);

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//     if (e.target.name === 'email') setEmailError('');
//     if (e.target.name === 'password') setPasswordError('');
//     if (e.target.name === 'youtubeChannel') setYoutubeChannelError('');
//   };

//   const validateEmail = (email) => {
//     const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return regex.test(email);
//   };

//   const validatePassword = (password) => {
//     return password.length >= 6;
//   };

//   const validateYoutubeChannel = async (channelHandle) => {
//     const youtubeApiKey = 'AIzaSyDrvc1bhX95Y4ViJc12c_bd0mkmI3DJEfQ';
    
//     const url = `https://www.googleapis.com/youtube/v3/channels?part=id&forHandle=${channelHandle}&key=${youtubeApiKey}`;  
//     try {
//       const response = await axios.get(url);
//       console.log("YouTube API Response: ", response.data);
      
//       return response.data.items && response.data.items.length > 0;
//     } catch (error) {
//       console.error("Error checking YouTube channel: ", error);
//       return false;
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (loading) return;
//     let isValid = true;
  
//     if (!validateEmail(formData.email)) {
//       setEmailError('Please enter a valid email address');
//       isValid = false;
//     }
  
//     if (!validatePassword(formData.password)) {
//       setPasswordError('Password must be at least 6 characters long');
//       isValid = false;
//     }
  
//     const channelName = formData.youtubeChannel.startsWith('@') ? formData.youtubeChannel.substring(1) : formData.youtubeChannel;
//     const isYoutubeChannelValid = await validateYoutubeChannel(channelName);
  
//     if (!isYoutubeChannelValid) {
//       setYoutubeChannelError('YouTube channel does not exist');
//       isValid = false;
//     }
  
//     if (!isValid) {
//       return;
//     }
  
//     console.log('Form submitted');
//     console.log('Form data: ', formData);
  
//     try {
//       setLoading(true);
//       setIsProcessing(true);
//       const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
//       console.log('User created: ', userCredential);
  
//       // Add user data to Firestore
//       await axios.post('https://us-central1-youtubecommentssummary.cloudfunctions.net/createUser', {
//         userId: userCredential.user.uid,
//         firstName: formData.firstName,
//         lastName: formData.lastName,
//         email: formData.email,
//         youtubeChannel: formData.youtubeChannel,
//       });
  
//       // Call the new function to activate the user without Stripe
//       const response = await axios.post('https://us-central1-youtubecommentssummary.cloudfunctions.net/activateUserWithoutStripe', {
//         userId: userCredential.user.uid,
//       });
  
//       console.log('User activated without Stripe payment');
  
//       // Redirect to the membership status update page
//       if (response.status === 200) {
//         window.location.href = `https://use-luminate.com/update-membership-status?session_id=${userCredential.user.uid}`;
//       } else {
//         throw new Error('Error activating user');
//       }
//     } catch (error) {
//       console.error('Error signing up: ', error);
//       setLoading(false);
//       setIsProcessing(false);
  
//       // Handle specific Firebase errors
//       if (error.code === 'auth/email-already-in-use') {
//         setEmailError('This email is already in use. Please use a different email.');
//       } else {
//         // General error message
//         setEmailError('An error occurred during sign up. Please try again later.');
//       }
//     }
//   };

//   return (
//     <FormContainer>
//       <CloseButton onClick={onClose}>x</CloseButton>
//       <Form onSubmit={handleSubmit}>
//         {/* <NameContainer>
//           <HalfInput
//             type="text"
//             id="firstName"
//             name="firstName"
//             required
//             placeholder="First Name"
//             value={formData.firstName}
//             onChange={handleChange}
//           />
//           <HalfInput
//             type="text"
//             id="lastName"
//             name="lastName"
//             required
//             placeholder="Last Name"
//             value={formData.lastName}
//             onChange={handleChange}
//           />
//         </NameContainer> */}

//         <Input
//           type="text"
//           id="youtubeChannel"
//           name="youtubeChannel"
//           required
//           placeholder="@YoutubeHandle"
//           value={formData.youtubeChannel}
//           onChange={handleChange}
//         />
//         {youtubeChannelError && <ErrorMessage>{youtubeChannelError}</ErrorMessage>}
//         <Input
//           type="email"
//           id="email"
//           name="email"
//           required
//           placeholder="example@email.com"
//           value={formData.email}
//           onChange={handleChange}
//         />
//         {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
//         <Input
//           type="password"
//           id="password"
//           name="password"
//           required
//           placeholder="Password"
//           value={formData.password}
//           onChange={handleChange}
//         />
//         {passwordError && <ErrorMessage>{passwordError}</ErrorMessage>}
//         <Button type="submit" disabled={loading}>
//           {loading ? (
//             <>
//               Processing
//               <Spinner />
//             </>
//           ) : (
//             'Start your Free Trial'
//           )}
//         </Button>
//       </Form>
//       {/* <InfoContainer>
//         <Users className="mr-2" size={16} /> 
//         <span>10,286 users growing their channels right now.</span>
//       </InfoContainer> */}
//     </FormContainer>
//   );
// };

// export default FormComponent;





















