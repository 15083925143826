import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterWrapper = styled.footer`
  // background-color: #f5f5f5;
  background-color: #FDFBFD;
  width: 100%;
  padding: 40px 0;
  border-top: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FooterLogo = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const FooterLink = styled(Link)`
  color: #FF0100;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    text-decoration: underline;
  }
`;

const FooterComponent = () => (
  <FooterWrapper>
    <FooterLogo>Luminate</FooterLogo>
    <FooterLinks>
      <FooterLink to="/contact">Contact</FooterLink>
      {/* <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
      <FooterLink to="/terms-of-service">Terms of Service</FooterLink> */}
    </FooterLinks>
  </FooterWrapper>
);

export default FooterComponent;
